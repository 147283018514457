export function getValueRowNames(rowNames) {
  return rowNames.map(setting => setting.name);
}

export function getSystemKeyByName(name, rowNames) {
  const item = rowNames.find(setting => setting.name === name);
  if (item) {
    return item.system_key;
  }
  return null;
}
