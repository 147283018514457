import React from 'react';
import PropTypes from 'prop-types';

const orders = `
＜お申込み内容＞
　■ 分析コース: 分析コース1
　■ 分析点数: 合計 1 点
　■ 内訳
　　□ 受付番号: 123-1
　　　　圃場名（作付）... 1 点
`;

const replacePlaceholder = (text) => {
  const dict = {
    '%company_name%': 'テスト農園',
    '%user_name%': '亜久里　太郎',
    '%orders%': orders,
    '%course%': '分析コース1',
  };
  let ret = text;
  const keys = Object.keys(dict);
  keys.forEach((key) => {
    ret = ret.replace(key, dict[key]);
  });
  return ret;
};

const MultilineText = (res) => {
  if (typeof res.children !== 'string') {
    return null;
  }

  const regExp = /(\n)/g;
  const text = replacePlaceholder(res.children);
  return text.split(regExp).map((line, i) => {
    if (line.match(regExp)) {
      return <br key={i} />; // eslint-disable-line
    }
    return line;
  });
};

const styles = {
  outer: {
    fontSize: '14px',
    lineHeight: 1.33,
    marginBottom: '8px',
    marginTop: '16px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '12px',
    lineHeight: 1,
    marginBottom: '8px',
  },
};

export const Preview = (props) => {
  const { label, text } = props;
  return (
    <div style={styles.outer}>
      <div style={styles.label}>{label}</div>
      <MultilineText>{text}</MultilineText>
    </div>
  );
};

Preview.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
