import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogContentText from '@material-ui/core/DialogContentText/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Tooltip from '@material-ui/core/Tooltip/index';

import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton/index';
import Pageview from '@material-ui/icons/Pageview';

import {
  SoilDiagItem,
} from '../../../proptypes';

const MIN_WIDTH = 96;

export const Preview = (props) => {
  const {
    data,
    date,
    lowerLimit,
    receiptId,
    receiptSubId,
    soilDiagItems,
    title,
    upperLimit,
  } = props;

  const [isOpened, setIsOpened] = useState(false);

  // HACK!
  // e.stopPropagation()でクリックした時に背面の行が選択されるのを抑制してる
  const onClickOuter = useCallback((e) => {
    e.stopPropagation();
  }, []);
  const onClickOpen = useCallback((e) => {
    e.stopPropagation();
    setIsOpened(true);
  }, []);
  const onClickClose = useCallback((e) => {
    e.stopPropagation();
    setIsOpened(false);
  }, []);

  const itemIds = Array.from(new Set([].concat(
    data ? data.map(d => d.item_id) : [],
    upperLimit ? upperLimit.map(d => d.item_id) : [],
    lowerLimit ? lowerLimit.map(d => d.item_id) : [],
  )));

  return (
    <div onClick={onClickOuter}>
      <IconButton onClick={onClickOpen}>
        <Pageview />
      </IconButton>
      <Dialog
        maxWidth="md"
        open={isOpened}
        onClose={onClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {body} */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ minWidth: MIN_WIDTH }}>受付番号</TableCell>
                  <TableCell align="center" style={{ minWidth: MIN_WIDTH }}>枝番</TableCell>
                  <TableCell align="center" style={{ minWidth: MIN_WIDTH }}>採土日</TableCell>
                  <TableCell align="center" style={{ minWidth: MIN_WIDTH + 16 }}>区別</TableCell>
                  <DataHeaderCells items={soilDiagItems} itemIds={itemIds} />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key="row.v1">
                  <ValueCell value={receiptId} />
                  <ValueCell value={receiptSubId} />
                  <DateCell value={date} />
                  <TableCell>分析値</TableCell>
                  <DataBodyCells data={data} itemIds={itemIds} />
                </TableRow>
                <TableRow key="row.v2">
                  <ValueCell value={receiptId} />
                  <ValueCell value={receiptSubId} />
                  <TableCell />
                  <TableCell>診断基準値（高）</TableCell>
                  <DataBodyCells data={upperLimit} itemIds={itemIds} />
                </TableRow>
                <TableRow key="row.v3">
                  <ValueCell value={receiptId} />
                  <ValueCell value={receiptSubId} />
                  <TableCell />
                  <TableCell>診断基準値（低）</TableCell>
                  <DataBodyCells data={lowerLimit} itemIds={itemIds} />
                </TableRow>
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickClose} color="primary" autoFocus>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Data = PropTypes.shape({
  itemId: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  computed: PropTypes.number.isRequired,
});

Preview.propTypes = {
  data: PropTypes.arrayOf(Data).isRequired,
  date: PropTypes.string.isRequired,
  lowerLimit: PropTypes.arrayOf(Data).isRequired,
  receiptId: PropTypes.number.isRequired,
  receiptSubId: PropTypes.number.isRequired,
  soilDiagItems: PropTypes.arrayOf(SoilDiagItem).isRequired,
  upperLimit: PropTypes.arrayOf(Data).isRequired,
  title: PropTypes.string.isRequired,
};


const DataHeaderCells = (props) => {
  const {
    items,
    itemIds,
  } = props;
  return (
    <>
      {
        itemIds.map((id) => {
          if (!items) {
            return <TableCell key={id} style={{ minWidth: MIN_WIDTH }} />;
          }
          const item = items.find(d => d.id === id);
          if (!item) {
            return <TableCell key={id} style={{ minWidth: MIN_WIDTH }} />;
          }
          return (
            <TableCell key={id} align="center" style={{ minWidth: MIN_WIDTH }}>
              {item.name}
            </TableCell>
          );
        })
      }
    </>
  );
};
DataHeaderCells.propTypes = {
  items: PropTypes.arrayOf(SoilDiagItem).isRequired,
  itemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};


const DataBodyCells = (props) => {
  const {
    data,
    itemIds,
  } = props;
  return (
    <>
      {
        itemIds.map((id) => {
          if (!data) {
            return <TableCell key={id} style={{ minWidth: MIN_WIDTH }} />;
          }
          const item = data.find(d => d.item_id === id);
          if (!item) {
            return <TableCell key={id} style={{ minWidth: MIN_WIDTH }} />;
          }
          return (
            <TableCell key={id} align="center" style={{ minWidth: MIN_WIDTH }}>
              {item.computed}
            </TableCell>
          );
        })
      }
    </>
  );
};
DataBodyCells.propTypes = {
  data: PropTypes.arrayOf(Data).isRequired,
  itemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};


const ValueCell = (props) => {
  const { value } = props;
  if (Number.isNaN(value)) {
    return (
      <TableCell align="center">
        <Tooltip title="値が正しくありません">
          <ErrorIcon color="error" />
        </Tooltip>
      </TableCell>
    );
  }
  return (
    <TableCell align="right">
      {value}
    </TableCell>
  );
};

ValueCell.propTypes = {
  value: PropTypes.number.isRequired,
};

const DateCell = (props) => {
  const { value } = props;
  if (!value || value === 'Invalid date') {
    return (
      <TableCell align="center">
        <Tooltip title="値が正しくありません">
          <ErrorIcon color="error" />
        </Tooltip>
      </TableCell>
    );
  }

  return (
    <TableCell align="right">
      {value}
    </TableCell>
  );
};

DateCell.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
};
