import React, { useEffect, useState } from 'react';

import { getLoginInfo } from '../api/requester';

import MiraizouCsvUpload from '../components/csv_upload/miraizou/upload_csv';
import SeikakenCsvUpload from '../components/csv_upload/seikaken/upload_csv';
import TanbayaCsvUpload from '../components/csv_upload/tanbaya/upload_csv';

const UploadCsv = () => {
  const [loginInfo, setLoginInfo] = useState(null);

  useEffect(() => {
    getLoginInfo()
      .then((json) => {
        setLoginInfo(json);
      });
  }, []);

  if (!loginInfo) {
    return null;
  }

  const { option_service } = loginInfo;
  const { service_subtype, service_type } = option_service;

  if (service_type !== 'soil_diagnosis') {
    return null;
  }

  return (
    <div>
      <h3>分析結果一括登録</h3>
      { service_subtype === 'miraizou' && <MiraizouCsvUpload /> }
      { service_subtype === 'seikaken' && <SeikakenCsvUpload /> }
      { service_subtype === 'tanbaya' && <TanbayaCsvUpload /> }
    </div>
  );
};

export default UploadCsv;
