import Encoding from 'encoding-japanese';
import { unparse as convertToCSV } from 'papaparse';

import { translate } from '../i18n';

export function toShiftJisCSV(source, entityName) {
  const keys = Object.keys(source[0]);
  const csv = convertToCSV({
    data: source,
    fields: keys,
  });

  const csvRows = csv.split('\n');
  const csvHeader = csvRows.shift(0);
  // objectのkeyとしてinvalidなのでヘッダー項目に,が含まれる可能性は考慮しない
  const jpHeader = csvHeader.split(',').map((key) => {
    const translateKey = `resources.${entityName}.fields.${key.trim()}`;
    return translate(translateKey, 'users');
  }).join(',');
  const jpCsv = [].concat(jpHeader, csvRows).join('\n');

  // なぜかUTF8から直接SJISにできないのでUNICODEを経由する
  const unicodeCsv = Encoding.stringToCode(jpCsv);
  const shiftJisCsv = Encoding.convert(unicodeCsv, 'SJIS', 'UNICODE');
  return new Uint8Array(shiftJisCsv);
}
