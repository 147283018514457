import {
  API_BASE,
  putAttachmentSuccess,
  postAttachmentUploadFormFor,
  postSoilDiagnosisAttachment,
  postForS3,
} from './requester';

export async function attachFileToApplication(id, file, options) {
  if (options && options.hockBeforeActualUploadStarts) {
    options.hockBeforeActualUploadStarts();
  }
  const body = {
    file_name: file.name,
    is_image: false,
  };
  const uploadForm = await postAttachmentUploadFormFor(body);
  const {
    post_form,
    uuid,
  } = uploadForm;
  const {
    action,
    algorithm,
    content_type,
    credential,
    date,
    key,
    policy64,
    signature,
  } = post_form;
  const s3Url = action;

  const formData = new FormData();
  formData.append('X-Amz-Algorithm', algorithm);
  formData.append('Content-Type', content_type);
  formData.append('X-Amz-Credential', credential);
  formData.append('X-Amz-Date', date);
  formData.append('key', key);
  formData.append('Policy', policy64);
  formData.append('X-Amz-Signature', signature);
  formData.append('file', file);

  await postForS3(s3Url, formData);
  await putAttachmentSuccess(uuid);
  await postSoilDiagnosisAttachment(id, { uuid });
}

export function getFileUrl(uuid) {
  return `${API_BASE}/v2/attachments/${uuid}/original`;
}
