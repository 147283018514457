import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import { refreshView } from 'react-admin';

import Grey from '@material-ui/core/colors/grey';
import { useScrollToTop } from '../../../hooks/use_scroll_to_top';

import {
  deleteResultDelivery,
  postResultDelivery,
} from '../../../api/requester';

import {
  DeliveryButton,
  EditButton,
  ExpandLessButton,
  ExpandMoreButton,
  UndeliveryButton,
} from '../button';
import { LabeledItem, Memo } from './item';
import { RuleTable } from './rule_table';
import { FileList } from './file_list';

import {
  Application,
  SoilDiagResult,
  Item,
  Rule,
} from '../../../proptypes';

const DoDeliveryButton = (props) => {
  const { id, isDelivered, onDelivered } = props;
  const [disable, setDisable] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = useCallback(() => setShowSnackbar(false), []);
  const onClick = useCallback(() => {
    const msg = '分析結果の配信を行います。';
    if (!window.confirm(msg)) {
      return;
    }
    setDisable(true);
    postResultDelivery([id])
      .then(() => {
        setMessage('配信に成功しました。');
        onDelivered();
      })
      .catch((e) => {
        setMessage('配信に失敗しました。');
        console.error(e); // eslint-disable-line
      })
      .finally(() => {
        setShowSnackbar(true);
        setDisable(false);
      });
  }, []);

  return (
    <>
      {
        !isDelivered && (
          <DeliveryButton
            disable={disable}
            onClick={onClick}
          />
        )
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={handleClose}
        open={showSnackbar}
        message={message}
      />
    </>
  );
};

DoDeliveryButton.propTypes = {
  id: PropTypes.number.isRequired,
  isDelivered: PropTypes.bool.isRequired,
  onDelivered: PropTypes.func.isRequired,
};

const DoCancelButton = (props) => {
  const { id, isDelivered, onUndelivered } = props;
  const [disable, setDisable] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = useCallback(() => setShowSnackbar(false), []);
  const onClick = useCallback(() => {
    const msg = '分析結果の配信の取り消しを行います。';
    if (!window.confirm(msg)) {
      return;
    }

    setDisable(true);
    deleteResultDelivery(id)
      .then(() => {
        setMessage('配信取消に成功しました。');
        onUndelivered();
      })
      .catch((e) => {
        setMessage('配信取消に失敗しました。');
        console.error(e); // eslint-disable-line
      })
      .finally(() => {
        setShowSnackbar(true);
        setDisable(false);
      });
  }, []);

  return (
    <>
      {
        isDelivered && (
          <UndeliveryButton
            disable={disable}
            onClick={onClick}
          />
        )
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={handleClose}
        open={showSnackbar}
        message={message}
      />
    </>
  );
};

DoCancelButton.propTypes = {
  id: PropTypes.number.isRequired,
  isDelivered: PropTypes.bool.isRequired,
  onUndelivered: PropTypes.func.isRequired,
};


const ShowFormStyles = {
  outer: {
    paddingTop: '32px',
  },
  titleOuter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
  },
  titleInner: {
    flex: '3 0 auto',
  },
  titleAside: {
    flex: '1 0 auto',
    textAlign: 'right',
  },
  resultValues: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 48,
  },
  footer: {
    backgroundColor: Grey['50'],
    margin: '16px -24px -16px -24px',
    padding: '16px 24px',
  },
};

const _ShowForm = (props) => {
  const {
    app,
    body,
    classes,
    items,
    refresh,
    rule,
    toggleToEdit,
  } = props;

  useScrollToTop();

  const [isExpandedRule, setIsExpandedRule] = useState(false);

  const inputtedItemIds = items.filter(i => i.is_computed === false).map(i => i.id);
  const inputtedValues = body.data.filter(d => inputtedItemIds.includes(d.item_id));

  const onClickExpandMore = useCallback(() => setIsExpandedRule(true), []);
  const onClickExpandLess = useCallback(() => setIsExpandedRule(false), []);

  const onDelivered = useCallback(() => refresh(), [body]);
  const onUndelivered = useCallback(() => refresh(), [body]);

  const isDelivered = !!app.result_delivered_at;

  return (
    <div className={classes.outer}>
      <div className={classes.titleOuter}>
        <div className={classes.titleInner}>
          <Typography variant="title">土壌診断結果</Typography>
        </div>
        <div className={classes.titleAside}>
          {
            !isDelivered && (
              <EditButton
                id={app.id}
                onClick={toggleToEdit}
              />
            )
          }
        </div>
      </div>
      <div className={classes.resultValues}>
        {
          inputtedValues.map((v) => {
            const item = items.find(i => i.id === v.item_id);
            if (!item) {
              return null;
            }
            return (
              <LabeledItem
                key={v.item_id}
                label={item.name}
                value={v.computed}
                unit={item.unit}
              />
            );
          })
        }
      </div>

      <Typography variant="title" gutterBottom>土壌診断基準</Typography>
      <div style={{ marginBottom: 48 }}>
        <Memo text={rule.name} />
        { !isExpandedRule && <ExpandMoreButton onClick={onClickExpandMore} /> }
        <Collapse in={isExpandedRule}>
          <RuleTable items={items} rule={rule} />
        </Collapse>
        { isExpandedRule && <ExpandLessButton onClick={onClickExpandLess} /> }
      </div>

      <Typography variant="title" gutterBottom>添付ファイル</Typography>
      <div style={{ marginBottom: 48 }}>
        <FileList files={body.files} />
      </div>

      <div className={classes.footer}>
        <DoDeliveryButton
          id={app.id}
          isDelivered={isDelivered}
          onDelivered={onDelivered}
        />
        <DoCancelButton
          id={app.id}
          isDelivered={isDelivered}
          onUndelivered={onUndelivered}
        />
      </div>
    </div>
  );
};

_ShowForm.propTypes = {
  app: Application.isRequired,
  body: SoilDiagResult.isRequired,
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(Item).isRequired,
  refresh: PropTypes.func.isRequired,
  rule: Rule.isRequired,
  toggleToEdit: PropTypes.func.isRequired,
};

export const ShowForm = withStyles(ShowFormStyles)(
  connect(null, { refresh: refreshView })(_ShowForm),
);
