import React from 'react';

import {
  CardActions,
  DateField,
  DateInput,
  EmailField,
  ExportButton,
  downloadCSV,
  Filter,
  List,
  SelectField,
  SelectInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';

import {
  getLocale,
} from '../utils/locale';

import {
  getUsersAll,
} from '../api/requester';

import {
  toShiftJisCSV,
} from '../csv/csv_exporter';

import {
  UserStatuses,
} from '../choices';

const UserFilter = props => (
  <Filter {...props}>
    <SelectInput source="status" choices={UserStatuses} />

    <TextInput source="company_name" />
    <TextInput source="company_name_kana" />
    <TextInput source="producer_name" />
    <TextInput source="producer_name_kana" />

    <TextInput source="zip_code" />
    <TextInput source="prefecture" />
    <TextInput source="address" />
    <TextInput source="address2" />

    <TextInput source="email" />
    <TextInput source="phone" />
    <TextInput source="fax_number" />
    <TextInput source="mobile_phone" />

    <DateInput source="created" />
  </Filter>
);

/* eslint-disable react/prop-types */
const UserListActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;

  const maxResults = 100; // TODO あとでちゃんとする

  return (
    <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <ExportButton
        maxResults={maxResults}
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </CardActions>
  );
};
/* eslint-enable react/prop-types */

const exporter = () => {
  getUsersAll().then((res) => {
    downloadCSV(toShiftJisCSV(res, 'users'), 'users');
  });
};

export const UserList = (props) => {
  // 初期のrenderなどでpropsにtranslateが渡されてない時はkeyをそのまま返す
  let translate = key => key;
  if (props.translate) {
    translate = props.translate; // eslint-disable-line
  }
  const t = key => translate(`resources.users.fields.${key}`);

  const defaultColumns = [
    'id',
    'status',
    'company_name',
    'prefecture',
    'created',
  ];

  return translate(
    <List
      {...props}
      actions={<UserListActions />}
      bulkActionButtons={false}
      exporter={exporter}
      filters={<UserFilter />}
    >
      <CustomizableDatagrid defaultColumns={defaultColumns} rowClick="show">
        <TextField source="id" label={t('id')} />
        <SelectField source="status" choices={UserStatuses} label={t('status')} />

        <TextField source="company_name" label={t('company_name')} />
        <TextField source="company_name_kana" label={t('company_name_kana')} />
        <TextField source="producer_name" label={t('producer_name')} />
        <TextField source="producer_name_kana" label={t('producer_name_kana')} />

        <TextField source="zip_code" label={t('zip_code')} />
        <TextField source="prefecture" label={t('prefecture')} />
        <TextField source="address" label={t('address')} />
        <TextField source="address2" label={t('address2')} />

        <EmailField source="email" label={t('email')} />
        <TextField source="phone" label={t('phone')} />
        <TextField source="fax_number" label={t('fax_number')} />
        <TextField source="mobile_phone" label={t('mobile_phone')} />

        <DateField source="created" locales={getLocale()} label={t('created')} />

      </CustomizableDatagrid>
    </List>,
  );
};

export const UserShow = (props) => {
  let translate = key => key;
  if (props.translate) { // eslint-disable-line
    translate = props.translate; // eslint-disable-line
  }
  const t = key => translate(`ui.users.${key}`);

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label={t('details')}>
          <TextField source="id" />
          <SelectField source="status" choices={UserStatuses} />

          <TextField source="company_name" />
          <TextField source="company_name_kana" />
          <TextField source="producer_name" />
          <TextField source="producer_name_kana" />

          <DateField source="created" locales={getLocale()} />
        </Tab>
        <Tab label={t('contacts')}>
          <TextField source="zip_code" />
          <TextField source="prefecture" />
          <TextField source="address" />
          <TextField source="address2" />

          <EmailField source="email" />
          <TextField source="phone" />
          <TextField source="fax_number" />
          <TextField source="mobile_phone" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
