import {
  ApplicationList as SeikakenApplicationList,
  ApplicationShow as SeikakenApplicationShow,
} from '../pages/seikaken/application';

import {
  ApplicationList as MiraizouApplicationList,
  ApplicationShow as MiraizouApplicationShow,
} from '../pages/miraizou/application';

import {
  ApplicationList as TanbayaApplicationList,
  ApplicationShow as TanbayaApplicationShow,
} from '../pages/tanbaya/application';

export function isSeikaken(permissions) {
  return permissions && permissions.provider === 'seikaken';
}

export function isMiraizou(permissions) {
  return permissions && permissions.provider === 'miraizou';
}

export function isTanbaya(permissions) {
  return permissions && permissions.provider === 'tanbaya';
}

export const getApplicationList = (permission) => {
  if (isSeikaken(permission)) {
    return SeikakenApplicationList;
  }

  if (isMiraizou(permission)) {
    return MiraizouApplicationList;
  }

  if (isTanbaya(permission)) {
    return TanbayaApplicationList;
  }

  return null;
};

export const getApplicationShow = (permission) => {
  if (isSeikaken(permission)) {
    return SeikakenApplicationShow;
  }

  if (isMiraizou(permission)) {
    return MiraizouApplicationShow;
  }

  if (isTanbaya(permission)) {
    return TanbayaApplicationShow;
  }

  return null;
};
