import validator from 'validator';

export function validate(values) {
  const errors = {};
  if (validator.isEmpty(values.title)) {
    errors.title = 'タイトルは必須です';
  }

  if (validator.isEmpty(values.body)) {
    errors.body = '本文は必須です。';
  }

  return errors;
}
