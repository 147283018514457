import React, { useEffect, useState } from 'react';

import {
  ArrayInput,
  CardActions,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  downloadCSV,
  Edit,
  ExportButton,
  Filter,
  List,
  LongTextInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';

import {
  getSoilDiagItems,
  getSoilDiagRulesAll,
} from '../api/requester';
import {
  toShiftJisCSV,
} from '../csv/csv_exporter';
import { createValidator } from '../specifications/soil_diagnosis_rule';
import { getLocale } from '../utils/locale';

const classes = {
  line: 'simple-form-iterator-line',
};

const SoilDiagnosisRuleFilter = props => (
  <Filter {...props}>
    <TextInput source="provider_code" alwaysOn />
    <TextInput source="name" alwaysOn />
    <DateInput source="updated_at" alwaysOn />
  </Filter>
);

/* eslint-disable react/prop-types */
const SoilDiagnosisRuleActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;

  const maxResults = 100; // TODO あとでちゃんとする

  return (
    <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      <ExportButton
        maxResults={maxResults}
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </CardActions>
  );
};
/* eslint-enable react/prop-types */

const exporter = () => {
  Promise.all([
    getSoilDiagRulesAll(),
    getSoilDiagItems(),
  ]).then((responses) => {
    const rules = responses[0];
    const items = responses[1];

    const csv = [];
    rules.forEach((rule) => {
      const base = {
        id: rule.id,
        provider_code: rule.provider_code,
        name: rule.name,
        created_at: rule.created_at,
        updated_at: rule.updated_at,
        type: null,
      };

      // 診断項目の空枠を作成
      items.forEach(item => base[item.system_key] = '');

      // 上限値の列と下限値の列を作る
      const upper = { ...base };
      upper.type = '上限値';
      const lower = { ...base };
      lower.type = '下限値';

      // 値を埋めつつ出力用の配列に追加する
      rule.values.forEach((value) => {
        const item = items.find(i => i.id === value.item_id);
        if (!item) {
          return;
        }
        upper[item.system_key] = value.high;
        lower[item.system_key] = value.low;
      });
      csv.push(upper);
      csv.push(lower);
    });

    downloadCSV(toShiftJisCSV(csv, 'soil_diagnosis_rules'), 'soil_diagnosis_rules');
  });
};

export const SoilDiagnosisRuleList = props => (
  <List
    {...props}
    actions={<SoilDiagnosisRuleActions />}
    bulkActionButtons={false}
    exporter={exporter}
    filters={<SoilDiagnosisRuleFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="provider_code" />
      <TextField source="name" />
      <DateField source="updated_at" locales={getLocale()} />
    </Datagrid>
  </List>
);

const soilDiagnosisItemOptionRenderer = (choice) => {
  if (choice.unit) {
    return `${choice.name} (${choice.unit})`;
  }
  return choice.name;
};

export const SoilDiagnosisRuleCreate = (props) => {
  // 初期のrenderなどでpropsにtranslateが渡されてない時はkeyをそのまま返す
  let translate = key => key;
  if (props.translate) { // eslint-disable-line
    translate = props.translate; // eslint-disable-line
  }
  const t = key => translate(`resources.soil_diagnosis_rules.fields.${key}`);

  const [provideCodes, setProvideCodes] = useState([]);
  useEffect(() => {
    getSoilDiagRulesAll()
      .then((res) => {
        const ids = res.map(d => d.provider_code);
        setProvideCodes(ids);
      });
  }, []);

  const validate = createValidator(provideCodes);
  return (
    <Create {...props}>
      <SimpleForm validate={validate}>
        <TextInput source="provider_code" />
        <TextInput source="name" />
        <LongTextInput source="memo" />
        <ArrayInput source="values" validate={[required()]}>
          <SimpleFormIterator classes={classes}>
            <ReferenceInput source="item_id" reference="soil_diagnosis_items" label={t('item_id')}>
              <SelectInput optionText={soilDiagnosisItemOptionRenderer} />
            </ReferenceInput>
            <NumberInput source="high" label={t('high')} />
            <NumberInput source="low" label={t('low')} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const SoilDiagnosisRuleEdit = (props) => {
  // 初期のrenderなどでpropsにtranslateが渡されてない時はkeyをそのまま返す
  let translate = key => key;
  if (props.translate) { // eslint-disable-line
    translate = props.translate; // eslint-disable-line
  }
  const t = key => translate(`resources.soil_diagnosis_rules.fields.${key}`);

  const [provideCodes, setProvideCodes] = useState([]);
  useEffect(() => {
    getSoilDiagRulesAll()
      .then((res) => {
        const ids = res
          .filter(d => d.id !== parseInt(props.id)) // eslint-disable-line
          .map(d => d.provider_code);
        setProvideCodes(ids);
      });
  }, []);

  const validate = createValidator(provideCodes);
  return (
    <Edit {...props}>
      <SimpleForm validate={validate}>
        <TextInput source="provider_code" />
        <TextInput source="name" />
        <LongTextInput source="memo" />
        <ArrayInput source="values">
          <SimpleFormIterator classes={classes}>
            <ReferenceInput source="item_id" reference="soil_diagnosis_items" label={t('item_id')}>
              <SelectInput optionText={soilDiagnosisItemOptionRenderer} />
            </ReferenceInput>
            <NumberInput source="high" label={t('high')} />
            <NumberInput source="low" label={t('low')} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
