import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Grey from '@material-ui/core/colors/grey';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SendIcon from '@material-ui/icons/Send';

import {
  refreshView,
  setListSelectedIds,
} from 'react-admin';
import { connect } from 'react-redux';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';

import {
  getApplication,
  postApplicationCanceled,
  postApplicationCancelReverted,
  postApplicationPaymentReceived,
  postApplicationSoilReceived,
  postResultDelivery,
} from '../api/requester';


registerLocale('ja', ja);

const mapDispatchToProps = {
  refreshList: refreshView,
  refreshSelection: () => setListSelectedIds('applications', []),
};

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
});

const BulkDelivery = (props) => {
  const { classes, onClick } = props;
  return (
    <Button color="primary" className={classes.button} onClick={onClick}>
      <SendIcon color="primary" className={classes.leftIcon} />
      診断結果配信
    </Button>
  );
};

BulkDelivery.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const BulkDeliveryButton = withStyles(styles)(BulkDelivery);


const BulkSoilReceivedUpdate = (props) => {
  const { classes, onClick } = props;
  return (
    <Button color="primary" className={classes.button} onClick={onClick}>
      <DateRangeIcon color="primary" className={classes.leftIcon} />
      土壌到着日更新
    </Button>
  );
};

BulkSoilReceivedUpdate.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const BulkSoilReceivedUpdateButton = withStyles(styles)(BulkSoilReceivedUpdate);


const modalStyle = theme => ({
  button: {
    marginLeft: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit * 2,
    textAlign: 'right',
  },
  date: {
    border: `${Grey.A100} 1px solid`,
    borderRadius: theme.shape.borderRadius,
    fontSize: '14px',
    padding: theme.spacing.unit,
  },
  dateBlock: {
    padding: '16px',
    paddingTop: 0,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  group: {
    margin: theme.spacing.unit,
  },
  inner: {
    width: 400,
    height: 400,
  },
  paper: {
    display: 'block',
    padding: theme.spacing.unit * 2,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw',
  },
  titleBlock: {
    padding: theme.spacing.unit * 2,
  },
});

const OVERWRITE_SELECTION = {
  DO_NOT: 'do_not_overwrite',
  DO: 'do_overwrite',
};

const _SoilReceivedUpdateModal = (props) => {
  const {
    classes,
    closeFunc,
    ids,
    open,
    refreshList,
    refreshSelection,
  } = props;

  const [date, setDate] = useState((new Date()));
  const [
    overwriteSelection,
    setOverwriteSelection,
  ] = useState(OVERWRITE_SELECTION.DO_NOT);

  const handleChangeDate = useCallback((nextDate) => {
    setDate(nextDate);
  }, []);

  const handleChangeOverwrite = useCallback((event) => {
    setOverwriteSelection(event.target.value);
  }, []);

  const onClickSubmit = useCallback(() => {
    const submitFunc = async () => {
      const promises = ids.map(id => new Promise((resolve, reject) => {
        getApplication(id)
          .then(res => resolve(res))
          .catch(e => reject(e));
      }));
      const apps = await Promise.all(promises);
      const targetIds = apps.reduce((memo, app) => {
        if (app.status === 'canceled') {
          return memo;
        }

        if (overwriteSelection === OVERWRITE_SELECTION.DO_NOT) {
          if (app.status === 'waiting_for_soil') {
            memo.push(app.id);
          }
        } else {
          memo.push(app.id);
        }
        return memo;
      }, []);
      await postApplicationSoilReceived(targetIds, date.toISOString());
      refreshSelection();
      refreshList();
      closeFunc();
    };
    submitFunc();
  }, [ids, date, overwriteSelection]);

  const onClickCancel = useCallback(() => closeFunc(), []);

  return (
    <Modal open={open}>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Paper>
            <div className={classes.paper}>
              <div className={classes.titleBlock}>
                <h3>土壌到着日の一括記録</h3>
              </div>
              <div className={classes.dateBlock}>
                <DatePicker
                  className={classes.date}
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                  onChange={handleChangeDate}
                  selected={date}
                />
              </div>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="overwrite"
                  name="overwrite"
                  className={classes.group}
                  value={overwriteSelection}
                  onChange={handleChangeOverwrite}
                >
                  <FormControlLabel
                    value={OVERWRITE_SELECTION.DO_NOT}
                    control={<Radio />}
                    label="既存の記録を上書きしない"
                  />
                  <FormControlLabel
                    value={OVERWRITE_SELECTION.DO}
                    control={<Radio />}
                    label="既存の記録を上書きする"
                  />
                </RadioGroup>
              </FormControl>
              <div className={classes.buttons}>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClickCancel}
                >
                  キャンセル
                </Button>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClickSubmit}
                >
                  一括記録
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

_SoilReceivedUpdateModal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeFunc: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  refreshSelection: PropTypes.func.isRequired,
};

export const SoilReceivedUpdateModal = withStyles(modalStyle)(
  connect(null, mapDispatchToProps)(_SoilReceivedUpdateModal),
);

const BulkPaymentReceivedUpdate = (props) => {
  const { classes, onClick } = props;
  return (
    <Button color="primary" className={classes.button} onClick={onClick}>
      <AttachMoneyIcon color="primary" className={classes.leftIcon} />
      入金確認日更新
    </Button>
  );
};

BulkPaymentReceivedUpdate.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const BulkPaymentReceivedUpdateButton = withStyles(styles)(BulkPaymentReceivedUpdate);


const _PaymentReceivedUpdateModal = (props) => {
  const {
    classes,
    closeFunc,
    ids,
    open,
    refreshList,
    refreshSelection,
  } = props;

  const [date, setDate] = useState((new Date()));
  const [
    overwriteSelection,
    setOverwriteSelection,
  ] = useState(OVERWRITE_SELECTION.DO_NOT);

  const handleChangeDate = useCallback((nextDate) => {
    setDate(nextDate);
  }, []);

  const handleChangeOverwrite = useCallback((event) => {
    setOverwriteSelection(event.target.value);
  }, []);

  const onClickSubmit = useCallback(() => {
    const submitFunc = async () => {
      const promises = ids.map(id => new Promise((resolve, reject) => {
        getApplication(id)
          .then(res => resolve(res))
          .catch(e => reject(e));
      }));
      const apps = await Promise.all(promises);
      const targetIds = apps.reduce((memo, app) => {
        if (app.status === 'canceled' || app.status === 'waiting_for_soil') {
          return memo;
        }

        if (overwriteSelection === OVERWRITE_SELECTION.DO_NOT) {
          if (app.status === 'waiting_for_payment') {
            memo.push(app.id);
          }
        } else {
          memo.push(app.id);
        }
        return memo;
      }, []);
      await postApplicationPaymentReceived(targetIds, date.toISOString());
      refreshSelection();
      refreshList();
      closeFunc();
    };
    submitFunc();
  }, [ids, date, overwriteSelection]);

  const onClickCancel = useCallback(() => closeFunc(), []);

  return (
    <Modal open={open}>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Paper>
            <div className={classes.paper}>
              <div className={classes.titleBlock}>
                <h3>入金確認日の一括記録</h3>
              </div>
              <div className={classes.dateBlock}>
                <DatePicker
                  className={classes.date}
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                  onChange={handleChangeDate}
                  selected={date}
                />
              </div>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="overwrite"
                  name="overwrite"
                  className={classes.group}
                  value={overwriteSelection}
                  onChange={handleChangeOverwrite}
                >
                  <FormControlLabel
                    value={OVERWRITE_SELECTION.DO_NOT}
                    control={<Radio />}
                    label="既存の記録を上書きしない"
                  />
                  <FormControlLabel
                    value={OVERWRITE_SELECTION.DO}
                    control={<Radio />}
                    label="既存の記録を上書きする"
                  />
                </RadioGroup>
              </FormControl>
              <div className={classes.buttons}>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClickCancel}
                >
                  キャンセル
                </Button>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClickSubmit}
                >
                  一括記録
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

_PaymentReceivedUpdateModal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeFunc: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  refreshSelection: PropTypes.func.isRequired,
};

export const PaymentReceivedUpdateModal = withStyles(modalStyle)(
  connect(null, mapDispatchToProps)(_PaymentReceivedUpdateModal),
);


const _DeliveryModal = (props) => {
  const {
    classes,
    closeFunc,
    ids,
    open,
    refreshList,
    refreshSelection,
  } = props;

  const onClickSubmit = useCallback(() => {
    const submitFunc = async () => {
      const promises = ids.map(id => new Promise((resolve, reject) => {
        getApplication(id)
          .then(res => resolve(res))
          .catch(e => reject(e));
      }));
      const apps = await Promise.all(promises);
      const targetIds = apps.reduce((memo, app) => {
        if (app.status === 'waiting_for_delivery') {
          memo.push(app.id);
        }
        return memo;
      }, []);
      await postResultDelivery(targetIds);
      refreshSelection();
      refreshList();
      closeFunc();
    };
    submitFunc();
  }, [ids]);

  const onClickCancel = useCallback(() => closeFunc(), []);

  return (
    <Modal open={open}>
      <div className={classes.root}>
        <div className={classes.inner}>
          <Paper>
            <div className={classes.paper}>
              <div className={classes.titleBlock}>
                <h3>一括配信</h3>
                <p>配信待ちのものだけが配信されます</p>
              </div>
              <div className={classes.buttons}>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClickCancel}
                >
                  キャンセル
                </Button>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={onClickSubmit}
                >
                  一括配信
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

_DeliveryModal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeFunc: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  refreshList: PropTypes.func.isRequired,
  refreshSelection: PropTypes.func.isRequired,
};

export const DeliveryModal = withStyles(modalStyle)(
  connect(null, mapDispatchToProps)(_DeliveryModal),
);


const _AppCancelButton = (props) => {
  const {
    id,
    refreshList,
    refreshSelection,
  } = props;

  const onClick = useCallback(() => {
    const msg = '申し込みをキャンセルします。';
    if (!window.confirm(msg)) {
      return;
    }

    postApplicationCanceled([id])
      .finally(() => {
        refreshSelection();
        refreshList();
      });
  }, [id]);

  return <Button color="primary" onClick={onClick}>申し込みをキャンセルする</Button>;
};

_AppCancelButton.propTypes = {
  id: PropTypes.number.isRequired,
  refreshList: PropTypes.func.isRequired,
  refreshSelection: PropTypes.func.isRequired,
};

export const AppCancelButton = connect(null, mapDispatchToProps)(_AppCancelButton);


export const _RevertAppCancelButton = (props) => {
  const {
    id,
    refreshList,
    refreshSelection,
  } = props;

  const onClick = useCallback(() => {
    const msg = '申し込みのキャンセルを取り消します。';
    if (!window.confirm(msg)) {
      return;
    }

    postApplicationCancelReverted([id])
      .finally(() => {
        refreshSelection();
        refreshList();
      });
  }, [id]);

  return <Button color="primary" onClick={onClick}>キャンセルの取り消しをする</Button>;
};

_RevertAppCancelButton.propTypes = {
  id: PropTypes.number.isRequired,
  refreshList: PropTypes.func.isRequired,
  refreshSelection: PropTypes.func.isRequired,
};

export const RevertAppCancelButton = connect(null, mapDispatchToProps)(_RevertAppCancelButton);
