import queryString from 'query-string';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

const credentials = 'same-origin';

function request(method, url, headers, body) {
  const init = {
    method,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    credentials,
  };

  if (body) {
    init.body = JSON.stringify(body);
  }

  const req = new Request(url, init);
  return fetch(req).then((response) => {
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    return response;
  });
}

// 汎用的に使える関数
export function get(url, headers = {}) {
  return request('GET', url, headers);
}

export function post(url, body, headers = {}) {
  return request('POST', url, headers, body);
}

export function put(url, body, headers = {}) {
  return request('PUT', url, headers, body);
}

export function del(url, headers = {}) {
  return request('DELETE', url, headers);
}

export function generateSplitPageNumbers(total, perPage, parallel) {
  const totalPage = Math.ceil(total / perPage);

  // 2ページ目から最後のページまでの数字を配列にする
  const requestPageNumbers = Array.from({ length: totalPage - 1 }).map((_, i) => i + 2);

  // ページ番号を`parallel`ごとに区切って配列にする
  const splitPageNumbers = [];
  for (let i = 0; i < requestPageNumbers.length; i += parallel) {
    splitPageNumbers.push(requestPageNumbers.slice(i, i + parallel));
  }

  return splitPageNumbers;
}

export async function getWithPaging(target, headers = {}) {
  const PER_PAGE = 100;
  const PARALLEL = 4;
  const { url, query } = queryString.parseUrl(target);
  const firstParams = {
    ...query,
    _page: 1,
    _perPage: PER_PAGE,
  };
  const firstQueries = queryString.stringify(firstParams);
  const firstResponse = await get(`${url}?${firstQueries}`, headers);
  const firstResult = await firstResponse.json();
  const totalCount = firstResponse.headers.get('X-Total-Count');

  // HTTP2では複数リクエストが同時に送信されてしまうため制限をつける
  const splitPageNumbers = generateSplitPageNumbers(totalCount, PER_PAGE, PARALLEL);
  const result = [...firstResult];
  // eslint-disable-next-line no-restricted-syntax
  for (const splitPageNumber of splitPageNumbers) {
    const requests = splitPageNumber.map(async (page) => {
      const nextParams = {
        ...query,
        _page: page,
        _perPage: PER_PAGE,
      };
      const nextQueries = queryString.stringify(nextParams);
      const response = get(`${url}?${nextQueries}`, headers);
      return response.then(r => r.json());
    });
    // eslint-disable-next-line no-await-in-loop
    const restResults = await Promise.all(requests);
    restResults.forEach(restResult => result.push(...restResult));
  }

  return result;
}

// S3専用の関数
function requestForS3(method, url, headers, body) {
  const init = {
    method,
    body,
    mode: 'cors',
  };

  const req = new Request(url, init);
  return fetch(req).then((response) => {
    if (response.status === 401) {
      window.location.href = '/#/login';
    } else if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    return null; // POSTがNo Contentなのでとりあえずはnullを返しておく
  });
}

export function postForS3(url, body, headers = {}) {
  return requestForS3('POST', url, headers, body);
}

// 個別のAPIごとの関数
export const API_BASE = '/soil-diag-admin-api';

export function getLoginInfo() {
  const url = `${API_BASE}/v2/login_info`;
  return get(url).then(res => res.json());
}

export function getApplication(id) {
  const url = `${API_BASE}/v2/applications/${id}`;
  return get(url).then(res => res.json());
}

export function putApplication(body) {
  const url = `${API_BASE}/v2/applications/${body.id}`;
  return put(url, body).then(res => res.json());
}

export function getApplicationByNumber(id, subId) {
  const url = `${API_BASE}/v2/applications/by_number/${id}/${subId}`;
  return get(url).then(res => res.json());
}

export function postApplicationSoilReceived(ids, receivedAt) {
  const url = `${API_BASE}/v2/applications/soil_received`;
  const body = {
    application_ids: ids,
    soil_received_at: receivedAt,
  };
  return post(url, body).then(res => res.json());
}

export function postApplicationPaymentReceived(ids, receivedAt) {
  const url = `${API_BASE}/v2/applications/payment_received`;
  const body = {
    application_ids: ids,
    payment_received_at: receivedAt,
  };
  return post(url, body).then(res => res.json());
}

export function postApplicationCanceled(ids) {
  const url = `${API_BASE}/v2/applications/canceled`;
  const body = {
    application_ids: ids,
  };
  return post(url, body).then(res => res.json());
}

export function postApplicationCancelReverted(ids) {
  const url = `${API_BASE}/v2/applications/canceled_reverted`;
  const body = {
    application_ids: ids,
  };
  return post(url, body).then(res => res.json());
}

export function getSoilDiagItems() {
  const url = `${API_BASE}/v2/soil_diagnosis_items`;
  return get(url).then(res => res.json());
}

export function getSoilDiagResult(id) {
  const url = `${API_BASE}/v2/applications/${id}/soil_diagnosis_result`;
  return get(url).then(res => res.json());
}

export function postSoilDiagResult(id, body) {
  const url = `${API_BASE}/v2/applications/${id}/soil_diagnosis_result`;
  return post(url, body).then(res => res.json());
}

export function putSoilDiagResult(id, body) {
  const url = `${API_BASE}/v2/applications/${id}/soil_diagnosis_result`;
  return put(url, body).then(res => res.json());
}


export function postSoilDiagnosisAttachment(id, body) {
  const url = `${API_BASE}/v2/applications/${id}/soil_diagnosis_result/attachments`;
  return post(url, body).then(res => res.json());
}

export function postAttachmentUploadFormFor(body) {
  const url = `${API_BASE}/v2/attachments/upload_form_for`;
  return post(url, body).then(res => res.json());
}

export function putAttachmentSuccess(uuid) {
  const url = `${API_BASE}/v2/attachments/${uuid}/success`;
  return put(url).then(res => res.json());
}

export function getSoilDiagRules() {
  const params = {
    _page: 1,
    _perPage: 100,
    _sortDir: 'DESC',
    _sortField: 'id',
  };
  const qs = queryString.stringify(params);
  const url = `${API_BASE}/v2/soil_diagnosis_rules?${qs}`;
  return get(url).then(res => res.json());
}

export function getSoilDiagRulesAll() {
  const url = `${API_BASE}/v2/soil_diagnosis_rules?_sortDir=DESC&_sortField=id`;
  return getWithPaging(url);
}

export function getSoilDiagRule(id) {
  const url = `${API_BASE}/v2/soil_diagnosis_rules/${id}`;
  return get(url).then(res => res.json());
}

export function postSoilDiagRule(body) {
  const url = `${API_BASE}/v2/soil_diagnosis_rules`;
  return post(url, body).then(res => res.json());
}

export function putSoilDiagRule(id, body) {
  const url = `${API_BASE}/v2/soil_diagnosis_rules/${id}`;
  return put(url, body).then(res => res.json());
}

export function postResultDelivery(ids) {
  const url = `${API_BASE}/v2/result_delivery`;
  const body = {
    application_ids: ids,
  };
  return post(url, body).then(res => res.json());
}

export function deleteResultDelivery(id) {
  const url = `${API_BASE}/v2/result_delivery/${id}`;
  return del(url).then(res => res.json());
}

export function getSummary(items) {
  const params = {
    items,
  };
  const qs = queryString.stringify(params, { arrayFormat: 'bracket' });
  const url = `${API_BASE}/v2/summary?${qs}`;
  return get(url).then(res => res.json());
}

export function getUsersAll() {
  const url = `${API_BASE}/v2/users?_sortDir=DESC&_sortField=id`;
  return getWithPaging(url);
}

export function getApplicationsAll() {
  const url = `${API_BASE}/v2/applications?_sortDir=DESC&_sortField=id`;
  return getWithPaging(url);
}
