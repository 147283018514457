import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { getLoginInfo } from './requester';


function isLoginCookieExpired() {
  // swagger-apiでテストするときはcookieを無効にする
  // if (process.env.REACT_APP_DEV) {
  //   return false;
  // }
  return document.cookie.indexOf('sda_login_status') < 0
    || document.cookie.indexOf('sda_login_status=deleted') >= 0; // ExpiresがSessionのクッキーのために必要
}


const headers = new Headers({
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
});
const credentials = 'same-origin';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;

    const request = new Request('/soil-diag-admin-api/v2/tokens/by_cookie', {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers,
      credentials,
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(() => getLoginInfo())
      .then((res) => {
        const {
          account,
          option_service,
          user,
        } = res;
        localStorage.setItem('provider', option_service.service_subtype);
        localStorage.setItem('company_name', user.company_name);
        localStorage.setItem('account_name', account.name);
      });
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    const request = new Request('/soil-diag-admin-api/v2/tokens/by_cookie', {
      method: 'DELETE',
      headers,
      credentials,
    });

    return fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      localStorage.removeItem('provider');
      localStorage.removeItem('company_name');
      localStorage.removeItem('account_name');
      return null;
    });
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  }

  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const expired = isLoginCookieExpired();
    return !expired ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const provider = localStorage.getItem('provider');
    return provider ? Promise.resolve({ provider }) : Promise.reject();
  }


  return Promise.reject(new Error('Unknown method'));
};
