import Papa from 'papaparse';

export function load(csv) {
  return new Promise((resolve) => {
    const complete = (result) => {
      resolve(result.data);
    };
    Papa.parse(csv, {
      encoding: 'Shift-JIS',
      skipEmptyLines: true,
      complete,
    });
  });
}
