import React from 'react';
import {
  Admin,
  Login,
  Resource,
} from 'react-admin';

import authProvider from './api/auth_provider';
import dataProvider from './api/data_provider';
import i18nProvider from './i18n';

import Layout from './layout';
import theme from './theme';
import customRoutes from './routes';
import menu from './menu';

import Dashboard from './pages/dashboard';
import {
  UserList,
  UserShow,
} from './pages/users';

import {
  MessageTemplateEdit,
  MessageTemplateList,
} from './pages/message_template';
import {
  SoilDiagnosisRuleCreate,
  SoilDiagnosisRuleEdit,
  SoilDiagnosisRuleList,
} from './pages/soil_diagnosis_rule';

import {
  UserOperationLogList,
} from './pages/user_operation_logs';

import {
  getApplicationShow,
  getApplicationList,
} from './utils/provider';

const MyLoginPage = () => <Login backgroundImage="/assets/background.jpg" />;

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    dashboard={Dashboard}
    appLayout={Layout}
    locale="ja"
    loginPage={MyLoginPage}
    theme={theme}
    menu={menu}
    customRoutes={customRoutes}
  >
    {
      permissions => [
        <Resource
          name="users"
          list={UserList}
          show={UserShow}
        />,
        <Resource
          name="applications"
          list={getApplicationList(permissions)}
          show={getApplicationShow(permissions)}
        />,
        <Resource
          name="message_templates"
          list={MessageTemplateList}
          edit={MessageTemplateEdit}
        />,
        <Resource
          name="soil_diagnosis_rules"
          list={SoilDiagnosisRuleList}
          create={SoilDiagnosisRuleCreate}
          edit={SoilDiagnosisRuleEdit}
        />,
        <Resource
          name="soil_diagnosis_items"
        />,
        <Resource
          name="user_operation_logs"
          list={UserOperationLogList}
        />,
      ]
    }
  </Admin>
);

export default App;
