import PropTypes from 'prop-types';

export const Item = PropTypes.shape({
  id: PropTypes.number.isRequired,
  is_computed: PropTypes.bool.isRequired,
  is_default: PropTypes.bool.isRequired,
  is_system: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  system_key: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
});

export const Value = PropTypes.shape({
  id: PropTypes.number.isRequired,
  item_id: PropTypes.number.isRequired,
  high: PropTypes.number,
  low: PropTypes.number,
});

export const Rule = PropTypes.shape({
  id: PropTypes.number.isRequired,
  memo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(Value).isRequired,
});

export const Data = PropTypes.shape({
  id: PropTypes.number.isRequired,
  item_id: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  computed: PropTypes.number,
});

export const Attachment = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  file_name: PropTypes.string.isRequired,
  is_image: PropTypes.bool.isRequired,
});

export const Application = PropTypes.shape({
  address: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  agri_field_area: PropTypes.number.isRequired,
  agri_field_name: PropTypes.string.isRequired,
  canceled_at: PropTypes.string,
  company_name: PropTypes.string.isRequired,
  company_name_kana: PropTypes.string.isRequired,
  crop_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fax_number: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  mobile_phone: PropTypes.string.isRequired,
  payment_received_at: PropTypes.string,
  payment_requested_at: PropTypes.string,
  phone: PropTypes.string.isRequired,
  prefecture: PropTypes.string.isRequired,
  producer_name: PropTypes.string.isRequired,
  producer_name_kana: PropTypes.string.isRequired,
  project_name: PropTypes.string.isRequired,
  receipt_number: PropTypes.number.isRequired,
  receipt_sub_number: PropTypes.number.isRequired,
  result_delivered_at: PropTypes.string,
  result_registered_at: PropTypes.string,
  soil_received_at: PropTypes.string,
  status: PropTypes.string.isRequired,
  zip_code: PropTypes.string.isRequired,
});

export const SoilDiagResult = PropTypes.shape({
  agri_field_id: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(Data),
  date: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(Attachment).isRequired,
  other: PropTypes.string.isRequired,
  project_id: PropTypes.number,
  soil_diagnosis_rule_id: PropTypes.number,
});

// TODO あとでcamelcaseにする？
export const SoilDiagItem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  is_computed: PropTypes.bool.isRequired,
  is_default: PropTypes.bool.isRequired,
  is_system: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  system_key: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
});
