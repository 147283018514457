export const VALUE_ROW_NAMES = [
  {
    system_key: 'ph_h2o',
    name: '補正値',
  },
  {
    system_key: 'ec',
    name: 'ＥＣ',
  },
  {
    system_key: 'koukansei_sekkai',
    name: 'CaO',
  },
  {
    system_key: 'koukansei_kudo',
    name: 'MgO',
  },
  {
    system_key: 'koukansei_kari',
    name: 'K2O',
  },
  {
    system_key: 'yuukoutai_rinsan',
    name: 'Ｐ酸',
  },
  {
    system_key: 'sekkai_houwado',
    name: 'Ca飽',
  },
  {
    system_key: 'kudo_houwado',
    name: 'Mg飽',
  },
  {
    system_key: 'kari_houwado',
    name: 'K飽',
  },
  {
    system_key: 'enki_houwado',
    name: '塩飽',
  },
  {
    system_key: 'sekkai_kudohi',
    name: 'Ca/Mg',
  },
  {
    system_key: 'kudo_karihi',
    name: 'Mg/K',
  },
  {
    system_key: 'hushoku',
    name: '腐植',
  },
  {
    system_key: 'rinsan_kyuushuu_keisuu',
    name: 'Ｐ吸',
  },
  {
    system_key: 'cec',
    name: 'CEC',
  },
  {
    system_key: 'kakyuutai_dou',
    name: 'Ｃｕ',
  },
  {
    system_key: 'kakyuutai_aen',
    name: 'Ｚｎ',
  },
  {
    system_key: 'kakyuutai_mangan',
    name: 'Ｍｎ',
  },
  {
    system_key: 'kakyuutai_houso',
    name: 'Ｂ',
  },
  {
    system_key: 'syosantai_tisso',
    name: 'NO3ｰN',
  },
  {
    system_key: 'yuukoutai_keisan',
    name: 'Si酸',
  },
  {
    system_key: 'nessui_tyuusyutsusei_tisso',
    name: '熱水Ｎ',
  },
  {
    system_key: 'kakyuutai_tisso',
    name: '湛水N',
  },
];
