export const UserStatuses = [
  {
    id: 'active',
    name: '有効',
  },
  {
    id: 'inactive',
    name: '無効',
  },
];

export const MessageTemplateTimings = [
  {
    id: 'user_registered',
    name: 'ユーザー登録完了後',
  },
  {
    id: 'application_registered',
    name: '土壌診断申し込み受付後',
  },
  {
    id: 'soil_received',
    name: '土壌到着確認時',
  },
  {
    id: 'payment_received',
    name: '入金確認時',
  },
  {
    id: 'result_delivered',
    name: '診断結果送信時',
  },
  {
    id: 'canceled',
    name: '申し込み取り消し時',
  },
];

export const UserOperationLogCommands = [
  {
    id: 'COMMAND_APPLICATION_REGISTER',
    name: '申し込みの登録',
  },
  {
    id: 'COMMAND_APPLICATION_UPDATE',
    name: '申し込みの更新',
  },
  {
    id: 'COMMAND_APPLICATION_RESULT_CREATE',
    name: '分析結果の登録',
  },
  {
    id: 'COMMAND_APPLICATION_RESULT_UPDATE',
    name: '分析結果の更新',
  },
  {
    id: 'COMMAND_APPLICATION_RESULT_DELETE',
    name: '分析結果の削除',
  },
  {
    id: 'COMMAND_APPLICATION_RESULT_ADD_ATTACHMENT',
    name: '添付ファイルの追加',
  },
  {
    id: 'COMMAND_APPLICATION_RESULT_DELIVERED',
    name: '診断結果の配信',
  },
  {
    id: 'COMMAND_APPLICATION_RESULT_DELIVERY_UNDONE',
    name: '診断結果の配信取り消し',
  },
  {
    id: 'COMMAND_ATTACHMENT_UPLOAD',
    name: '添付ファイルのアップロード',
  },
  {
    id: 'COMMAND_MESSAGE_TEMPLATE_UPDATE',
    name: '定型文の更新',
  },
  {
    id: 'COMMAND_SOIL_DIAGNOSIS_RULE_CREATE',
    name: '土壌診断基準の作成',
  },
  {
    id: 'COMMAND_SOIL_DIAGNOSIS_RULE_UPDATE',
    name: '土壌診断基準の更新',
  },
  {
    id: 'COMMAND_SOIL_DIAGNOSIS_RULE_DELETE',
    name: '土壌診断基準の削除',
  },
];
