import React from 'react';
import PropTypes from 'prop-types';

import Gray from '@material-ui/core/colors/grey';

export const LabeledItem = (props) => {
  const {
    label,
    value,
    unit,
  } = props;
  return (
    <div style={{
      margin: 8,
      width: 200,
    }}
    >
      <div style={{
        color: Gray['500'],
        fontSize: '12px',
        height: '1.5em',
      }}
      >
        {label}
      </div>
      <div style={{
        borderBottom: `1px solid ${Gray['500']}`,
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <div style={{
          flex: '1 0 auto',
          paddingTop: 6,
          paddingBottom: 7,
        }}
        >
          {value !== null ? value : '　'}
        </div>
        <div style={{
          color: Gray['500'],
          flex: '1 0 auto',
          fontSize: '14px',
          marginRight: 8,
          paddingTop: 6,
          paddingBottom: 7,
          textAlign: 'right',
        }}
        >
          {unit}
        </div>
      </div>
    </div>
  );
};

LabeledItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  unit: PropTypes.string.isRequired,
};

LabeledItem.defaultProps = {
  value: null,
};


export const Memo = (props) => {
  const { text } = props;
  return (
    <div style={{
      margin: 8,
      width: '100%',
    }}
    >
      <div style={{
        borderBottom: `1px solid ${Gray['500']}`,
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <div style={{
          flex: '1 0 auto',
          paddingTop: 6,
          paddingBottom: 7,
        }}
        >
          {text || '　'}
        </div>
      </div>
    </div>
  );
};

Memo.propTypes = {
  text: PropTypes.string.isRequired,
};
