import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  hidden: {
    display: 'none',
  },
});

const Delivery = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
      <SendIcon className={classes.leftIcon} />
      配信する
    </Button>
  );
};
Delivery.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const DeliveryButton = withStyles(styles)(Delivery);

const Undelivery = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
      <CancelIcon className={classes.leftIcon} />
      配信取消
    </Button>
  );
};
Undelivery.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const UndeliveryButton = withStyles(styles)(Undelivery);

const Edit = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
      <EditIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
      編集する
    </Button>
  );
};
Edit.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const EditButton = withStyles(styles)(Edit);

const ExpandLess = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="outlined" color="primary" className={classes.button} onClick={onClick}>
      <ExpandLessIcon className={classes.leftIcon} />
      詳細を閉じる
    </Button>
  );
};
ExpandLess.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const ExpandLessButton = withStyles(styles)(ExpandLess);

const ExpandMore = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="outlined" color="primary" className={classes.button} onClick={onClick}>
      <ExpandMoreIcon className={classes.leftIcon} />
      詳細を開く
    </Button>
  );
};
ExpandMore.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const ExpandMoreButton = withStyles(styles)(ExpandMore);

const AttachFile = (props) => {
  const { classes, onChange } = props;
  return (
    <Button variant="contained" color="primary" component="label" className={classes.button}>
      <AttachFileIcon className={classes.leftIcon} />
      ファイルを追加する
      <input type="file" accept=".pdf" className={classes.hidden} multiple onChange={onChange} />
    </Button>
  );
};
AttachFile.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
export const AttachFileButton = withStyles(styles)(AttachFile);

const Save = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
      <SaveIcon className={classes.leftIcon} />
      保存
    </Button>
  );
};
Save.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const SaveButton = withStyles(styles)(Save);

const Cancel = (props) => {
  const { classes, onClick } = props;
  return (
    <Button variant="outlined" color="primary" className={classes.button} onClick={onClick}>
      キャンセル
    </Button>
  );
};
Cancel.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
export const CancelButton = withStyles(styles)(Cancel);
