import React from 'react';

import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  SelectField,
  TextField,
} from 'react-admin';

import {
  UserOperationLogCommands,
} from '../choices';

const UserOperationLogFilter = props => (
  <Filter {...props}>
    <DateInput source="created" alwaysOn />
  </Filter>
);

export const UserOperationLogList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<UserOperationLogFilter />}
  >
    <Datagrid>
      <NumberField source="id" />
      <SelectField source="command" choices={UserOperationLogCommands} sortable={false} />
      <NumberField source="parameters.id" sortable={false} />
      <NumberField source="parameters.receipt_number" sortable={false} />
      <NumberField source="parameters.receipt_sub_number" sortable={false} />
      <DateField source="created" showTime />
      <TextField source="account_id" sortable={false} />
      <TextField source="account_name" sortable={false} />
    </Datagrid>
  </List>
);
