export const VALUE_ROW_NAMES = [
  {
    system_key: 'ph_h2o',
    name: 'pH (H2O)',
  },
  {
    system_key: 'ph_kcl',
    name: 'pH (KCL)',
  },
  {
    system_key: 'ec',
    name: 'EC',
  },
  {
    system_key: 'yuukoutai_rinsan',
    name: '有効態リン酸',
  },
  {
    system_key: 'koukansei_sekkai',
    name: '交換性石灰',
  },
  {
    system_key: 'koukansei_kudo',
    name: '交換性苦土',
  },
  {
    system_key: 'koukansei_kari',
    name: '交換性カリ',
  },
  {
    system_key: 'sekkai_houwado',
    name: '石灰飽和度',
  },
  {
    system_key: 'kudo_houwado',
    name: '苦土飽和度',
  },
  {
    system_key: 'kari_houwado',
    name: 'カリ飽和度',
  },
  {
    system_key: 'enki_houwado',
    name: '総飽和度',
  },
  {
    system_key: 'cec',
    name: 'CEC',
  },
  {
    system_key: 'sekkai_kudohi',
    name: '石灰/苦土比',
  },
  {
    system_key: 'kudo_karihi',
    name: '苦土/カリ比',
  },
  {
    system_key: 'mukitai_tisso',
    name: '無機態窒素合計',
  },
  {
    system_key: 'syosantai_tisso',
    name: '硝酸態窒素',
  },
  {
    system_key: 'annmoniatai_tisso',
    name: 'アンモニア態窒素',
  },
  {
    system_key: 'hushoku',
    name: '腐植',
  },
  {
    system_key: 'rinsan_kyuushuu_keisuu',
    name: 'リン酸吸収係数',
  },
  {
    system_key: 'kosou',
    name: '土壌 (固相)',
  },
  {
    system_key: 'ekisou',
    name: '土壌 (液相)',
  },
  {
    system_key: 'kisou',
    name: '土壌 (気相)',
  },
  {
    system_key: 'karihijuu',
    name: '仮比重',
  },
  {
    system_key: 'kakyuutai_tetsu',
    name: '可給態鉄',
  },
  {
    system_key: 'koukansei_mangan',
    name: '交換性マンガン',
  },
  {
    system_key: 'kakyuutai_aen',
    name: '可給態亜鉛',
  },
  {
    system_key: 'kakyuutai_dou',
    name: '可給態銅',
  },
  {
    system_key: 'kakyuutai_houso',
    name: '可給態ホウ素',
  },
];
