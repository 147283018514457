export const ApplicationStatuses = [
  {
    id: 'waiting_for_soil',
    name: '土壌待ち',
  },
  {
    id: 'waiting_for_payment',
    name: '入金待ち',
  },
  {
    id: 'waiting_for_diagnosis',
    name: '分析待ち',
  },
  {
    id: 'waiting_for_delivery',
    name: '配信待ち',
  },
  {
    id: 'completed',
    name: '完了',
  },
  {
    id: 'canceled',
    name: '取り消し済み',
  },
];
