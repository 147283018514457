import React, { useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import createUUID from 'uuid/v4';

import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Grey from '@material-ui/core/colors/grey';

import { connect } from 'react-redux';
import { refreshView } from 'react-admin';

import { useScrollToTop } from '../../../hooks/use_scroll_to_top';

import {
  AttachFileButton,
  CancelButton,
  ExpandLessButton,
  ExpandMoreButton,
  SaveButton,
} from '../button';
import { Memo } from './item';
import { RuleTable } from './rule_table';
import { FileList } from './file_list';

import {
  Application,
  SoilDiagResult,
  Item,
  Rule,
} from '../../../proptypes';
import { updateSoilDiagnosisResult } from '../../../services/application';


const EditFormStyles = {
  outer: {
    paddingTop: '32px',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    height: 52,
    paddingBottom: 24,
  },
  resultValues: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 48,
  },
  input: {
    margin: 8,
    width: 200,
  },
  footer: {
    backgroundColor: Grey['50'],
    margin: '16px -24px -16px -24px',
    padding: '16px 24px',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateValue': {
      const nextData = state.data.map((d) => {
        const value = parseFloat(action.payload);
        if (d.item_id !== action.meta) {
          return d;
        }
        if (Number.isNaN(value)) {
          return {
            ...d,
            values: [null],
            computed: null,
          };
        }
        return {
          ...d,
          values: [value],
          computed: value,
        };
      });
      return {
        ...state,
        data: nextData,
      };
    }
    case 'deleteFile': {
      const nextFiles = state.files.filter(f => f.uuid !== action.payload);
      return {
        ...state,
        files: nextFiles,
      };
    }
    case 'addLocalFiles': {
      const nextFiles = state.localFiles.concat(action.payload);
      return {
        ...state,
        localFiles: nextFiles,
      };
    }
    case 'removeLocalFile': {
      const nextFiles = state.localFiles.filter(f => f.uuid !== action.payload);
      return {
        ...state,
        localFiles: nextFiles,
      };
    }
    default:
      return state;
  }
};

const _EditForm = (props) => {
  const {
    app,
    body,
    classes,
    items,
    refresh, // eslint-disable-line
    rule,
    toggleToShow,
  } = props;

  useScrollToTop();

  const [editedBody, dispatch] = useReducer(reducer, { ...body, localFiles: [] });
  const [isExpandedRule, setIsExpandedRule] = useState(false);

  const inputtedItemIds = items.filter(i => i.is_computed === false).map(i => i.id);
  const inputtedValues = editedBody.data.filter(d => inputtedItemIds.includes(d.item_id));

  const onClickExpandMore = useCallback(() => setIsExpandedRule(true), []);
  const onClickExpandLess = useCallback(() => setIsExpandedRule(false), []);

  const onClickDeleteFile = useCallback((uuid) => {
    dispatch({
      type: 'deleteFile',
      payload: uuid,
    });
  }, []);

  const onClickAttachFile = useCallback((e) => {
    const { files } = e.target;
    const { length } = files;
    const nextLocalFiles = [];
    for (let i = 0; i < length; i += 1) {
      nextLocalFiles.push({
        uuid: createUUID(),
        file_name: files[i].name,
        is_image: false,
        file: files[i],
      });
    }
    e.target.value = ''; // HACK: 同じファイルを再度選択できるようにするためのHACK
    dispatch({
      type: 'addLocalFiles',
      payload: nextLocalFiles,
    });
  }, []);

  const onClickRemoveAttachedFile = useCallback((uuid) => {
    dispatch({
      type: 'removeLocalFile',
      payload: uuid,
    });
  }, []);

  const onClickSave = () => {
    const nextBody = { ...editedBody };
    const files = nextBody.localFiles.map(f => f.file);
    delete nextBody.localFiles;
    updateSoilDiagnosisResult(app.id, nextBody, files)
      .then(() => toggleToShow());
  };

  return (
    <div className={classes.outer}>
      <div className={classes.title}>
        <Typography variant="title">土壌診断結果の編集</Typography>
      </div>
      <div className={classes.resultValues}>
        {
          inputtedValues.map((v) => {
            const item = items.find(i => i.id === v.item_id);
            if (!item) {
              return null;
            }
            return (
              <TextField
                type="number"
                key={v.item_id}
                className={classes.input}
                label={item.name}
                value={v.computed}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{item.unit}</InputAdornment>,
                }}
                onChange={(e) => {
                  dispatch({
                    type: 'updateValue',
                    payload: e.target.value,
                    meta: v.item_id,
                  });
                }}
              />
            );
          })
        }
      </div>

      <Typography variant="title" gutterBottom>土壌診断基準</Typography>
      <div style={{ marginBottom: 48 }}>
        <Memo text={rule.name} />
        { !isExpandedRule && <ExpandMoreButton onClick={onClickExpandMore} /> }
        <Collapse in={isExpandedRule}>
          <RuleTable items={items} rule={rule} />
        </Collapse>
        { isExpandedRule && <ExpandLessButton onClick={onClickExpandLess} /> }
      </div>

      <Typography variant="title" gutterBottom>添付ファイル</Typography>
      <div>
        <FileList files={editedBody.files} onClickDelete={onClickDeleteFile} />
        <FileList files={editedBody.localFiles} onClickDelete={onClickRemoveAttachedFile} />
        <div>
          <AttachFileButton type="file" onChange={onClickAttachFile} />
        </div>
      </div>

      <div className={classes.footer}>
        <SaveButton onClick={onClickSave} />
        <CancelButton onClick={toggleToShow} />
      </div>
    </div>
  );
};

_EditForm.propTypes = {
  app: Application.isRequired,
  body: SoilDiagResult.isRequired,
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(Item).isRequired,
  refresh: PropTypes.func.isRequired,
  rule: Rule.isRequired,
  toggleToShow: PropTypes.func.isRequired,
};

export const EditForm = withStyles(EditFormStyles)(
  connect(null, { refresh: refreshView })(_EditForm),
);
