import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';

import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

import { ShowForm } from './show';
import { EditForm } from './edit';

import {
  getApplication,
  getSoilDiagItems,
  getSoilDiagResult,
  getSoilDiagRule,
} from '../../api/requester';


const divStyle = {
  alignItems: 'center',
  display: 'flex',
  height: '300px',
  justifyContent: 'center',
  width: '100%',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'show': {
      return {
        ...state,
        isEdit: false,
      };
    }
    case 'edit': {
      return {
        ...state,
        isEdit: true,
      };
    }
    default:
      return state;
  }
};

export const ResultForm = (props) => {
  const { applicationId } = props;

  const [state, dispatch] = useReducer(reducer, { isEdit: false });
  const toggleToEdit = useCallback(() => {
    dispatch({
      type: 'edit',
    });
  }, []);
  const toggleToShow = useCallback(() => {
    dispatch({
      type: 'show',
    });
  }, []);

  const [isFetched, setIsFetched] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const [app, setApp] = useState(null);
  const [body, setBody] = useState(null);
  const [rule, setRule] = useState(null);
  const [items, setItems] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let fetchedBody;
      try {
        fetchedBody = await getSoilDiagResult(applicationId);
        setBody(fetchedBody);
      } catch (e) {
        if (e.message === 'Not Found') {
          setIsNotFound(true);
        } else {
          setHasError(true);
          console.error(e); // eslint-disable-line
        }
        setIsFetched(true);
        return;
      }

      try {
        const fetchedItems = await getSoilDiagItems();
        const fetchedApp = await getApplication(applicationId);
        const fetchedRule = await getSoilDiagRule(fetchedBody.soil_diagnosis_rule_id);
        setItems(fetchedItems);
        setApp(fetchedApp);
        setRule(fetchedRule);
      } catch (e) {
        setHasError(true);
        console.error(e); // eslint-disable-line
      }
      setIsFetched(true);
    }
    fetchData();
  }, [state.isEdit]);

  if (!isFetched) {
    return (
      <div style={divStyle}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div style={divStyle}>
        <InfoIcon />
        <span>
          診断結果が登録されていません。
        </span>
      </div>
    );
  }

  if (hasError) {
    return (
      <div style={divStyle}>
        <ErrorIcon />
        <span>
          エラーが発生しました。
        </span>
      </div>
    );
  }

  if (state.isEdit) {
    return (
      <EditForm
        app={app}
        body={body}
        items={items}
        rule={rule}
        toggleToShow={toggleToShow}
      />
    );
  }

  return (
    <ShowForm
      app={app}
      body={body}
      items={items}
      rule={rule}
      toggleToEdit={toggleToEdit}
    />
  );
};

ResultForm.propTypes = {
  applicationId: PropTypes.string.isRequired,
};
