import React from 'react';
import PropTypes from 'prop-types';

import {
  MenuItemLink,
  WithPermissions,
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import DashboardIcon from '@material-ui/icons/Dashboard';
import EmailIcon from '@material-ui/icons/Email';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DvrIcon from '@material-ui/icons/Dvr';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PeopleIcon from '@material-ui/icons/People';
import SubjectIcon from '@material-ui/icons/Subject';
import TuneIcon from '@material-ui/icons/Tune';

const Menu = (props) => {
  const { onMenuClick } = props;

  return (
    <div>
      <MenuItemLink
        to="/"
        primaryText="ダッシュボード"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
        exact
      />
      <MenuItemLink
        to="/users"
        primaryText="ユーザー"
        leftIcon={<PeopleIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/applications"
        primaryText="申し込み"
        leftIcon={<EmailIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/message_templates"
        primaryText="定型文"
        leftIcon={<SubjectIcon />}
        onClick={onMenuClick}
      />
      <WithPermissions
        render={({ permissions }) => (
          permissions && (permissions.provider === 'seikaken' || permissions.provider === 'tanbaya')
            ? (
              <MenuItemLink
                to="/soil_diagnosis_rules"
                primaryText="土壌診断基準"
                leftIcon={<TuneIcon />}
                onClick={onMenuClick}
              />
            )
            : null
        )}
      />
      <MenuItemLink
        to="/upload_csv"
        primaryText="分析結果一括登録"
        leftIcon={<LibraryBooksIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/upload_pdf"
        primaryText="添付ファイル一括登録"
        leftIcon={<PictureAsPdfIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/user_operation_logs"
        primaryText="操作ログ"
        leftIcon={<DvrIcon />}
        onClick={onMenuClick}
      />
    </div>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default withRouter(Menu);
