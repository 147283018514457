import React, {
  useCallback, useEffect, useReducer, useState,
} from 'react';
import PropTypes from 'prop-types';

import createUUID from 'uuid/v4';

import Button from '@material-ui/core/Button/index';
import { withStyles } from '@material-ui/core/styles/index';

import {
  nextStatus,
  ROW_STATUS,
} from '../../../utils/status';
import {
  getSoilDiagItems,
  getSoilDiagResult,
  getSoilDiagRulesAll,
  postSoilDiagResult,
  putSoilDiagResult,
} from '../../../api/requester';

import { load } from '../../../csv/csv_loader';
import {
  getValues,
  parseRow,
} from '../../../csv/tanbaya/csv_parser';

import { Dropzone } from '../../dropzone';
import { CsvTable } from './csv_table';

export const CSV_PARSED = 'CSV_PARSED';
export const RESET_ITEMS = 'RESET_ITEMS';
export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const UPDATE_ROW_APP_ID = 'UPDATE_ROW_APP_ID';
export const UPDATE_ROW_STATUS = 'UPDATE_ROW_STATUS';

const styles = theme => ({
  button: {
    marginRight: theme.spacing.unit * 2,
  },
  controls: {
    marginTop: theme.spacing.unit * 2,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case CSV_PARSED: {
      const appendRows = action.payload.map(item => ({
        appId: null,
        hasSoilDiagResult: false,
        isSelected: false,
        message: '',
        name: item.name,
        status: ROW_STATUS.NONE,
        uuid: createUUID(),
        parsed: {
          receiptId: item.receiptId,
          receiptSubId: item.receiptSubId,
          soilDiagRuleCode: item.soilDiagRuleCode,
          date: item.date,
          data: item.data,
        },
      }));
      return state.concat(appendRows);
    }

    case RESET_ITEMS:
      return [];

    case TOGGLE_ITEM:
      return state.map((item) => {
        if (action.meta === item.uuid) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return item;
      });

    case UPDATE_ROW_APP_ID:
      return state.map((item) => {
        if (action.meta === item.uuid) {
          return {
            ...item,
            appId: action.payload.id,
            hasSoilDiagResult: action.payload.hasSoilDiagResult,
          };
        }
        return item;
      });

    case UPDATE_ROW_STATUS:
      return state.map((item) => {
        if (action.meta === item.uuid) {
          return {
            ...item,
            ...nextStatus(item, action.payload),
          };
        }
        return item;
      });

    default:
      return state;
  }
};

const UploadCsv = (props) => {
  const { classes } = props;

  const [tableRows, dispatch] = useReducer(reducer, []);
  const [files, setFiles] = useState([]);
  const [soilDiagItems, setSoilDiagItems] = useState([]);
  const [soilDiagRules, setSoilDiagRules] = useState([]);

  useEffect(() => {
    const func = async () => {
      const resSoilDiagItems = await getSoilDiagItems();
      setSoilDiagItems(resSoilDiagItems);
      const resSoilDiagRules = await getSoilDiagRulesAll();
      setSoilDiagRules(resSoilDiagRules);
    };
    func();
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      files.forEach((file) => {
        load(file)
          .then((res) => {
            const header = res[0];
            const body = res.slice(1);
            const rows = body.map((row) => {
              const parsed = parseRow(row, header, soilDiagItems);
              return {
                ...parsed,
                name: file.name,
                uuid: file.uuid,
              };
            });
            const action = {
              type: CSV_PARSED,
              payload: rows,
            };
            dispatch(action);
          });
      });
    }
  }, [files]);

  const onFileDropped = useCallback(droppedFiles => setFiles(droppedFiles), []);

  const toggleItem = useCallback((targetUuid) => {
    const action = {
      type: TOGGLE_ITEM,
      meta: targetUuid,
    };
    dispatch(action);
  }, []);

  const updateRowAppId = useCallback((uuid, id, hasSoilDiagResult) => {
    const action = {
      type: UPDATE_ROW_APP_ID,
      payload: {
        id,
        hasSoilDiagResult,
      },
      meta: uuid,
    };
    dispatch(action);
  }, []);

  const updateRowStatus = (uuid, status, message) => { // eslint-disable-line
    const action = {
      type: UPDATE_ROW_STATUS,
      payload: {
        message,
        status,
      },
      meta: uuid,
    };
    dispatch(action);
  };

  const onClickCancel = useCallback(() => {
    const action = {
      type: RESET_ITEMS,
    };
    dispatch(action);
  }, []);

  const isNoSelection = !tableRows.some((row) => {
    const isValid = [ROW_STATUS.READY, ROW_STATUS.INFO].includes(row.status);
    return isValid && row.isSelected;
  });
  const isFetchingItems = tableRows.some(row => row.status === ROW_STATUS.UPLOADING);
  const disableSubmit = isNoSelection || isFetchingItems;
  const disableCancel = isFetchingItems;

  const onClickSubmit = useCallback(() => {
    const registerResult = row => new Promise((resolve) => {
      const {
        appId,
        hasSoilDiagResult,
        parsed,
        uuid,
      } = row;
      const soilDiagRule = soilDiagRules.find(sdr => sdr.provider_code === parsed.soilDiagRuleCode);

      if (!soilDiagRule) {
        updateRowStatus(uuid, ROW_STATUS.ERROR, '分析結果の登録に失敗しました。');
        resolve();
      }

      const values = getValues(parsed);
      const body = {
        date: parsed.date,
        soil_diagnosis_rule_id: soilDiagRule.id,
        data: values,
        images: [],
        files: [],
        other: '',
      };

      if (!hasSoilDiagResult) {
        postSoilDiagResult(appId, body)
          .then(() => {
            updateRowStatus(uuid, ROW_STATUS.SUCCESS, '分析結果を登録しました。');
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line
            updateRowStatus(uuid, ROW_STATUS.ERROR, '分析結果の登録に失敗しました。');
          })
          .finally(() => resolve());
      } else {
        getSoilDiagResult(appId)
          .then((appBody) => {
            const { data } = appBody;
            const nextData = values.map((value) => {
              const current = data.find(d => d.item_id === value.item_id);
              if (current) {
                return {
                  ...current,
                  ...value,
                };
              }
              return {
                ...value,
              };
            });
            const nextAppBody = {
              ...appBody,
              data: nextData,
              date: parsed.date,
            };
            return putSoilDiagResult(appId, nextAppBody);
          })
          .then(() => {
            updateRowStatus(uuid, ROW_STATUS.SUCCESS, '分析結果を登録しました。');
          })
          .catch((e) => {
            console.error(e); // eslint-disable-line
            updateRowStatus(uuid, ROW_STATUS.ERROR, '分析結果の登録に失敗しました。');
          })
          .finally(() => resolve());
      }
    });

    const targetRows = tableRows.filter((tr) => {
      const isValid = [ROW_STATUS.READY, ROW_STATUS.INFO].includes(tr.status);
      return isValid && tr.isSelected;
    });
    const promises = targetRows.map(row => registerResult(row));
    Promise.all(promises);
  }, [tableRows]);

  if (tableRows.length === 0) {
    return (
      <div>
        <Dropzone onFileDropped={onFileDropped} />
      </div>
    );
  }

  return (
    <div>
      <CsvTable
        disabled={isFetchingItems}
        rows={tableRows}
        soilDiagItems={soilDiagItems}
        soilDiagRules={soilDiagRules}
        toggleItem={toggleItem}
        updateRowAppId={updateRowAppId}
        updateRowStatus={updateRowStatus}
      />
      <div className={classes.controls}>
        <Button
          className={classes.button}
          variant="outlined"
          disabled={disableCancel}
          onClick={onClickCancel}
        >
          ファイルの選択に戻る
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={disableSubmit}
          onClick={onClickSubmit}
        >
          チェックした項目を登録
        </Button>
      </div>
    </div>
  );
};

UploadCsv.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadCsv);
