// ra-language-japaneseの上書きしたい場所だけを定義する
// 下のoverride関数の修正し忘れに注意
const customized = {
  ra: {
    page: {
      edit: '%{name}',
      show: '%{name}',
    },
    auth: {
      sign_in: 'ログイン',
      logout: 'ログアウト',
    },
  },
};

export function override(original) {
  return {
    ...original,
    ra: {
      ...original.ra,
      page: {
        ...original.ra.page,
        ...customized.ra.page,
      },
      auth: {
        ...original.ra.auth,
        ...customized.ra.auth,
      },
    },
  };
}
