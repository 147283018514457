import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { getFileUrl } from '../../../api/s3_upload';

export const FileList = (props) => {
  const { files } = props;

  return (
    <List dense>
      {files.map(file => (
        <ListItem
          key={file.uuid}
          button
          component="a"
          href={getFileUrl(file.uuid)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${file.file_name}`} />
        </ListItem>
      ))}
    </List>
  );
};


const Attachment = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  file_name: PropTypes.string.isRequired,
  is_image: PropTypes.bool.isRequired,
});


FileList.propTypes = {
  files: PropTypes.arrayOf(Attachment).isRequired,
};
