import japaneseMessages from './ja';

const messages = {
  ja: japaneseMessages,
};

export const translate = (key) => {
  const lang = navigator.language;
  if (lang !== 'ja') {
    // 今のところ日本語以外は非対応
    return key.split('.').pop();
  }

  const translated = key.split('.').reduce((memo, k) => {
    const item = memo[k];
    return !item ? null : item;
  }, messages[lang]);

  if (!translated) {
    return key.split('.').pop();
  }

  return translated;
};

export default locale => messages[locale];
