import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  Item,
  Rule,
} from '../../../proptypes';

export const RuleTable = (props) => {
  const { items, rule } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>診断項目</TableCell>
          <TableCell>単位</TableCell>
          <TableCell>下限値</TableCell>
          <TableCell>上限値</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rule.values.map((value) => {
          const item = items.find(i => i.id === value.item_id);
          if (!item) {
            return null;
          }
          return (
            <TableRow key={value.item_id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.unit}</TableCell>
              <TableCell>{value.low}</TableCell>
              <TableCell>{value.high}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

RuleTable.propTypes = {
  items: PropTypes.arrayOf(Item).isRequired,
  rule: Rule.isRequired,
};
