import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress/index';

import DraftsIcon from '@material-ui/icons/Drafts';
import InboxIcon from '@material-ui/icons/Inbox';
import SearchIcon from '@material-ui/icons/Search';

import {
  getSummary,
} from '../../api/requester';

import Summary from '../../components/summary';

import {
  createFilteredLink,
} from '../../utils/link';


const styles = {
  outer: {
    display: 'flex',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
};

export default () => {
  const initialState = {
    applications_waiting_for_delivery: 0,
    applications_waiting_for_diagnosis: 0,
    applications_waiting_for_soil: 0,
  };
  const [summary, setSummary] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const items = ['awfs', 'awfd', 'awfd2'];
    getSummary(items)
      .then(json => setSummary(json))
      .catch(() => setHasError(true))
      .then(() => setIsLoading(false));
  }, []);

  if (hasError) {
    return <div>読み込みに失敗しました</div>;
  }

  if (isLoading) {
    return (
      <div style={styles.loader}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div style={styles.outer}>
      <Summary
        bgColor="#f57f17"
        icon={InboxIcon}
        label="土壌待ち"
        to={createFilteredLink('applications', 'waiting_for_soil')}
        value={summary.applications_waiting_for_soil}
      />
      <Summary
        bgColor="#33691e"
        icon={SearchIcon}
        label="分析待ち"
        to={createFilteredLink('applications', 'waiting_for_diagnosis')}
        value={summary.applications_waiting_for_diagnosis}
      />
      <Summary
        bgColor="#0d47a1"
        icon={DraftsIcon}
        label="配信待ち"
        to={createFilteredLink('applications', 'waiting_for_delivery')}
        value={summary.applications_waiting_for_delivery}
      />
    </div>
  );
};
