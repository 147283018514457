import queryString from 'query-string';

export function createFilteredLink(target, status) {
  const params = {
    filter: `{"status":"${status}"}`,
    page: 1,
    perPage: 10,
    order: 'DESC',
    sort: 'id',
  };
  const qs = queryString.stringify(params);
  return `/${target}?${qs}`;
}
