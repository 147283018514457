import validator from 'validator';

export const createValidator = (provideCodes = []) => (values) => {
  const errors = {};

  const {
    name,
    provider_code,
  } = values;

  const emptyOpts = { ignore_whitespace: true };

  if (provideCodes.includes(values.provider_code)) {
    errors.provider_code = ['すでに使用されている診断基準コードです。'];
  }

  if (provider_code === undefined || validator.isEmpty(provider_code, emptyOpts)) {
    errors.provider_code = ['診断基準コードは必須です。'];
  }

  if (name === undefined || validator.isEmpty(name, emptyOpts)) {
    errors.name = ['診断基準名は必須です。'];
  }

  return errors;
};
