import React, { useEffect, useState } from 'react';

import { getLoginInfo } from '../api/requester';

import SeikakenDashboard from './seikaken/dashboard';
import MiraizouDashboard from './miraizou/dashboard';
import TanbayaDashboard from './tanbaya/dashboard';

const Dashboard = () => {
  const [loginInfo, setLoginInfo] = useState(null);

  useEffect(() => {
    getLoginInfo()
      .then((json) => {
        setLoginInfo(json);
      });
  }, []);

  if (!loginInfo) {
    return null;
  }

  const { option_service } = loginInfo;
  const { service_subtype, service_type } = option_service;

  if (service_type !== 'soil_diagnosis') {
    return null;
  }

  return (
    <div>
      { service_subtype === 'miraizou' && <MiraizouDashboard /> }
      { service_subtype === 'seikaken' && <SeikakenDashboard /> }
      { service_subtype === 'tanbaya' && <TanbayaDashboard /> }
    </div>
  );
};

export default Dashboard;
