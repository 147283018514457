import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
// import { promised } from 'q';

const API_URL = '/soil-diag-admin-api/v2';


function buildFilter(filter) {
  return Object.keys(filter)
    .reduce((container, key) => {
      container[`_filters[${key}]`] = filter[key];
      return container;
    }, {});
}


const headers = new Headers({
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
});
const credentials = 'same-origin';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      const filters = buildFilter(params.filter);

      const query = {
        _sortField: field,
        _sortDir: order,
        _page: page,
        _perPage: perPage,
        ...filters,
      };

      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: { headers, credentials },
      };
    }

    case GET_ONE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { headers, credentials },
      };

    case GET_MANY: {
      // リストでReferenceFieldが表示されるときは全体を取得しなくてすむようにids=[...]で値が来る
      // API側をあまり変更しないでいいようにfiltersにする hack
      if (params.ids) {
        params.filter = params.filter ? params.filter : {};
        params.filter.id = params.ids;
      }

      const filters = buildFilter(params.filter);

      const query = {
        ...filters,
      };

      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: { headers, credentials },
      };
    }

    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      // src/views/resource_page/producers.js:205 あたりのsourceとtargetを渡すためのハック
      // 別のバグを起こしていそう
      if (params.target) {
        params.filter = params.filter ? params.filter : {};
        params.filter[params.target] = params.id;
      }

      const filters = buildFilter(params.filter);

      const query = {
        _sortField: field,
        _sortDir: order,
        _page: page,
        _perPage: perPage,
        ...filters,
      };

      return {
        url: `${API_URL}/${resource}?${stringify(query)}`,
        options: { headers, credentials },
      };
    }

    case UPDATE: {
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: {
          method: 'PUT', body: JSON.stringify(params.data), headers, credentials,
        },
      };
    }

    case CREATE: {
      return {
        url: `${API_URL}/${resource}`,
        options: {
          method: 'POST', body: JSON.stringify(params.data), headers, credentials,
        },
      };
    }

    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'DELETE', headers, credentials },
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = async (response, type, resource, params) => {
  const { headers, json } = response; // eslint-disable-line
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      return {
        data: json.map(x => x),
        total: parseInt(headers.get('x-total-count'), 10) || json.length,
      };
    case CREATE:
      if (json.password) {
        return { data: { ...params.data, id: json.id, password: json.password } };
      }
      return { data: { ...params.data, id: json.id } };
    case DELETE:
      return { data: { id: params.id } };

    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
  return fetchJson(url, options)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};
