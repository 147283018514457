import React from 'react';
import { Route } from 'react-router-dom';

import UploadCsv from './pages/upload_csv';
import UploadPdf from './pages/upload_pdf';

export default [
  <Route exact path="/upload_csv" component={UploadCsv} />,
  <Route exact path="/upload_pdf" component={UploadPdf} />,
];
