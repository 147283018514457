import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  wrapper: {
    position: 'fixed',
    bottom: theme.spacing.unit,
    right: theme.spacing.unit,
  },
});

const _ToTopButton = (props) => {
  const { classes } = props;
  const onClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={classes.wrapper}>
      <Button variant="fab" color="primary" aria-label="To Top" className={classes.fab} onClick={onClick}>
        <KeyboardArrowUpIcon />
      </Button>
    </div>
  );
};
_ToTopButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const ToTopButton = withStyles(styles)(_ToTopButton);
