import React from 'react';
import PropTypes from 'prop-types';

import {
  Datagrid,
  Edit,
  FormDataConsumer,
  List,
  LongTextInput,
  SaveButton,
  SelectField,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/index';

import { validate } from '../specifications/message_template';

import {
  MessageTemplateTimings,
} from '../choices';

import { Preview as MiraizouPreview } from '../components/preview/miraizou/preview';
import { Preview as SeikakenPreview } from '../components/preview/seikaken/preview';
import { Preview as TanbayaPreview } from '../components/preview/tanbaya/preview';

export const MessageTemplateList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <SelectField source="system_name" choices={MessageTemplateTimings} sortable={false} />
      <TextField source="title" sortable={false} />
    </Datagrid>
  </List>
);

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

const editStyles = {
  title: {
    width: '50%',
  },
};

const _MessageTemplateEdit = (props) => {
  const { classes, permissions } = props;

  // 初期のrenderなどでpropsにtranslateが渡されてない時はkeyをそのまま返す
  let translate = key => key;
  if (props.translate) { // eslint-disable-line
    translate = props.translate; // eslint-disable-line
  }
  const t = key => translate(`resources.message_templates.fields.${key}`);

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />} validate={validate}>
        <SelectField source="system_name" choices={MessageTemplateTimings} />
        <TextInput source="title" className={classes.title} />
        <FormDataConsumer>
          {({ formData }) => (
            <div>
              <Grid container spacing={24}>
                <Grid item xs={6}>
                  <LongTextInput source="body" label={t('body')} />
                </Grid>
                <Grid item xs={6}>
                  {
                    permissions && permissions.provider === 'miraizou' && (
                      <MiraizouPreview label="プレビュー" text={formData.body} />
                    )
                  }
                  {
                    permissions && permissions.provider === 'seikaken' && (
                      <SeikakenPreview label="プレビュー" text={formData.body} />
                    )
                  }
                  {
                    permissions && permissions.provider === 'tanbaya' && (
                      <TanbayaPreview label="プレビュー" text={formData.body} />
                    )
                  }
                </Grid>
              </Grid>
            </div>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

_MessageTemplateEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export const MessageTemplateEdit = withStyles(editStyles)(_MessageTemplateEdit);
