import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import status from './status';

import './index.css';
import App from './App';

if (process.env.REACT_APP_BUILD_TARGET !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: status.release,
    environment: process.env.REACT_APP_BUILD_TARGET,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
