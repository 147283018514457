import { createMuiTheme } from '@material-ui/core/styles';

import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#388e3c',
    },
    secondary: {
      main: '#5d4037',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default theme;
