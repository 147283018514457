import moment from 'moment';
import { VALUE_ROW_NAMES } from './value_rows';
import {
  getValueRowNames,
  getSystemKeyByName,
} from '../../utils/row_name';

// consts
export const COLUMN_TYPES = {
  ID: 'ID',
  VALUE: 'VALUE',
};

const RECEIPT_ID_COLUMN = '受付親番号';
const RECEIPT_SUB_ID_COLUMN = '受付子番号';
const RECEIVED_DATE_COLUMN = '受付日';
const RULE_COLUMN = '基準値名';

const ID_COLUMNS = [
  RECEIPT_ID_COLUMN,
  RECEIPT_SUB_ID_COLUMN,
];

const DATE_COLUMNS = [
  RECEIVED_DATE_COLUMN,
];

const VALUE_COLUMNS = getValueRowNames(VALUE_ROW_NAMES);

// util
export function getColumnType(index, header) {
  const label = header[index];

  if (ID_COLUMNS.includes(label)) {
    return COLUMN_TYPES.ID;
  }

  if (DATE_COLUMNS.includes(label)) {
    return COLUMN_TYPES.DATE;
  }

  if (VALUE_COLUMNS.includes(label)) {
    return COLUMN_TYPES.VALUE;
  }

  return null; // parse対象外
}

// validator
function validateId(value) {
  const regexp = /^([1-9]\d*)$/;
  if (!regexp.test(value)) {
    return false;
  }
  return !Number.isNaN(parseInt(value, 10));
}

function validateDate(value) {
  return !Number.isNaN((new Date(value)).getTime());
}

function validateValue(value) {
  if (value.trim() === '') {
    return true;
  }

  const regexp = /^((([1-9]\d*\.*\d*)|(0\.\d+))(未満)?)$/;
  if (!regexp.test(value)) {
    return false;
  }
  const csvStrippedValue = value.replace(',', '');
  return value.includes('未満') || !Number.isNaN(parseFloat(csvStrippedValue));
}

function validateColumn(value, index, header) {
  const columnType = getColumnType(index, header);

  switch (columnType) {
    case COLUMN_TYPES.ID:
      return validateId(value);

    case COLUMN_TYPES.DATE:
      return validateDate(value);

    case COLUMN_TYPES.VALUE:
      return validateValue(value);

    default:
      return true; // 対象外のカラム
  }
}

export function validateRow(row, header) {
  return row.every((col, i) => validateColumn(col, i, header));
}

// parser
function parseId(value) {
  return parseInt(value, 10);
}

function parseDate(value) {
  return moment(value).format('YYYY-MM-DD');
}

function parseValue(value) {
  if (value === '' || value === '＊') {
    return null;
  }

  const csvStrippedValue = value.replace(',', '');
  return parseFloat(csvStrippedValue);
}

function getValue(row, label, header) {
  const index = header.indexOf(label);
  if (index === -1) {
    return null;
  }
  return row[index];
}

// 行をパースする、エラーの項目はisNaNになる
export function parseRow(row, header, soilDiagItems) {
  const data = row.reduce((memo, value, i) => {
    if (!VALUE_COLUMNS.includes(header[i])) {
      return memo;
    }

    const parsedValue = parseValue(value);
    const item = soilDiagItems.find((sdi) => {
      const key = getSystemKeyByName(header[i], VALUE_ROW_NAMES);
      return sdi.system_key === key;
    });
    if (parsedValue !== null && item) {
      memo.push({
        itemId: item.id,
        values: [parsedValue],
        computed: parsedValue,
      });
    }
    return memo;
  }, []);

  return {
    receiptId: parseId(getValue(row, RECEIPT_ID_COLUMN, header)),
    receiptSubId: parseId(getValue(row, RECEIPT_SUB_ID_COLUMN, header)),
    soilDiagRuleCode: getValue(row, RULE_COLUMN, header),
    date: parseDate(getValue(row, RECEIVED_DATE_COLUMN, header)),
    data,
  };
}

// パース済オブジェクトからデータを取得する
// 値がnullの測定値は対象外として弾く
export function getValues(parsed) {
  const isValid = parsed.data.every(d => !Number.isNaN(d.computed));
  if (!isValid) {
    throw Error('Invalid value in CSV row');
  }
  return parsed.data
    .filter(d => d.computed !== null)
    .map(d => ({
      item_id: d.itemId,
      values: d.values,
      computed: d.computed,
    }));
}
