import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/core/SvgIcon/SvgIcon';
import WarningIcon from '@material-ui/icons/Warning';

import { ROW_STATUS } from '../utils/status';
import { getLocale } from '../utils/locale';

import {
  getApplicationByNumber,
  getSoilDiagResult,
} from '../api/requester';

// ^(\d+)_(\d+)_(.*)\.pdf
const regexp = /^(\d+)_(\d+)_(.*)\.pdf/;
const parseFileName = (filename) => {
  const result = regexp.exec(filename);
  if (result === null) {
    return {
      id: null,
      subId: null,
    };
  }
  return {
    id: result[1],
    subId: result[2],
  };
};


export const PdfTable = (props) => {
  const {
    disabled,
    rows,
    toggleItem,
    updateRowAppId,
    updateRowStatus,
  } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>ファイル名</TableCell>
          <TableCell>受付番号</TableCell>
          <TableCell>枝番</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>受付日</TableCell>
          <TableCell>氏名</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <PdfTableRow
            disabled={disabled}
            key={row.uuid}
            isSelected={row.isSelected}
            message={row.message}
            name={row.name}
            status={row.status}
            toggleItem={toggleItem}
            updateRowAppId={updateRowAppId}
            updateRowStatus={updateRowStatus}
            uuid={row.uuid}
          />
        ))}
      </TableBody>
    </Table>
  );
};

const Row = PropTypes.shape({
  isSelected: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
});

PdfTable.propTypes = {
  disabled: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(Row).isRequired,
  toggleItem: PropTypes.func.isRequired,
  updateRowAppId: PropTypes.func.isRequired,
  updateRowStatus: PropTypes.func.isRequired,
};


const PdfTableRow = (props) => {
  const {
    disabled,
    isSelected,
    message,
    name,
    toggleItem,
    status,
    updateRowAppId,
    updateRowStatus,
    uuid,
  } = props;

  const [application, setApplication] = useState({
    receipt_number: null,
    receipt_sub_number: null,
    company_name: null,
    payment_requested_at: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const ids = parseFileName(name);
    if (ids.id === null || ids.subId === null) {
      updateRowStatus(uuid, ROW_STATUS.WARNING, 'ファイル名が正しくありません。');
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      updateRowStatus(uuid, ROW_STATUS.LOADING, '');

      try {
        const applicationData = await getApplicationByNumber(ids.id, ids.subId);
        setApplication(applicationData);
        updateRowAppId(uuid, applicationData.id);

        if (applicationData.status === 'canceled') {
          updateRowStatus(uuid, ROW_STATUS.WARNING, 'すでにキャンセル済みの申し込みです。');
          return;
        }

        if (applicationData.status === 'completed') {
          updateRowStatus(uuid, ROW_STATUS.WARNING, 'すでに配信済みの申し込みです。添付ファイルを登録するには配信停止を先に行ってください。');
          return;
        }

        const result = await getSoilDiagResult(applicationData.id);
        if (result) {
          updateRowStatus(uuid, ROW_STATUS.READY, '');
        }
      } catch (e) {
        // 分析結果がない場合もここでハンドリングする
        updateRowStatus(uuid, ROW_STATUS.ERROR, '分析結果が登録されていません。');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onChangeCheckbox = useCallback(() => toggleItem(uuid), []);

  if (isLoading) {
    return (
      <TableRow key={uuid}>
        <TableCell>
          <Checkbox disabled />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell>読み込み中</TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    );
  }

  let requestedAt;
  if (application.payment_requested_at) {
    const locale = getLocale();
    const date = new Date(application.payment_requested_at);
    requestedAt = date.toLocaleDateString(locale, { timeZone: 'UTC' });
  }

  return (
    <TableRow key={uuid}>
      <TableCell>
        <Checkbox
          checked={isSelected}
          disabled={status !== ROW_STATUS.READY || disabled}
          onChange={onChangeCheckbox}
          value={uuid}
        />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{application.receipt_number}</TableCell>
      <TableCell>{application.receipt_sub_number}</TableCell>
      <TableCell>
        { status === ROW_STATUS.SUCCESS
        && (
          <Tooltip title={message}>
            <CheckCircleIcon color="primary" />
          </Tooltip>
        )
        }
        { status === ROW_STATUS.INFO
        && (
          <Tooltip title={message}>
            <InfoIcon color="secondary" />
          </Tooltip>
        )
        }
        { status === ROW_STATUS.WARNING
        && (
          <Tooltip title={message}>
            <WarningIcon color="secondary" />
          </Tooltip>
        )
        }
        { status === ROW_STATUS.ERROR
        && (
          <Tooltip title={message}>
            <ErrorIcon color="error" />
          </Tooltip>
        )
        }
        { (status === ROW_STATUS.LOADING || status === ROW_STATUS.UPLOADING)
        && (
          <CircularProgress color="secondary" size={20} />
        )
        }
      </TableCell>
      <TableCell>{requestedAt}</TableCell>
      <TableCell>{application.company_name}</TableCell>
    </TableRow>
  );
};

PdfTableRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  toggleItem: PropTypes.func.isRequired,
  updateRowAppId: PropTypes.func.isRequired,
  updateRowStatus: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};
