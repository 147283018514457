import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { ROW_STATUS } from '../../../utils/status';
import {
  Rule,
  SoilDiagItem,
} from '../../../proptypes';

import {
  getApplicationByNumber,
  getSoilDiagResult,
} from '../../../api/requester';

import { Preview } from './preview';


export const CsvTable = (props) => {
  const {
    rows,
    soilDiagItems,
    soilDiagRules,
    toggleItem,
    updateRowAppId,
    updateRowStatus,
  } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>ファイル名</TableCell>
          <TableCell>受付番号</TableCell>
          <TableCell>枝番</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>受付日</TableCell>
          <TableCell>氏名</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <CsvTableRow
            appId={row.appId}
            key={row.uuid}
            data={row.parsed.data}
            date={row.parsed.date}
            isSelected={row.isSelected}
            message={row.message}
            name={row.name}
            receiptId={row.parsed.receiptId}
            receiptSubId={row.parsed.receiptSubId}
            soilDiagItems={soilDiagItems}
            soilDiagRuleCode={row.parsed.soilDiagRuleCode}
            soilDiagRules={soilDiagRules}
            status={row.status}
            toggleItem={toggleItem}
            updateRowAppId={updateRowAppId}
            updateRowStatus={updateRowStatus}
            uuid={row.uuid}
          />
        ))}
      </TableBody>
    </Table>
  );
};


const Data = PropTypes.shape({
  itemId: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  computed: PropTypes.number.isRequired,
});

const Parsed = PropTypes.shape({
  receiptId: PropTypes.number.isRequired,
  receiptSubId: PropTypes.number.isRequired,
  soilDiagRuleCode: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Data).isRequired,
});

const Row = PropTypes.shape({
  appId: PropTypes.number,
  isSelected: PropTypes.bool.isRequired,
  hasSoilDiagResult: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parsed: Parsed,
  status: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
});

CsvTable.propTypes = {
  rows: PropTypes.arrayOf(Row).isRequired,
  soilDiagItems: PropTypes.arrayOf(SoilDiagItem).isRequired,
  soilDiagRules: PropTypes.arrayOf(Rule).isRequired,
  toggleItem: PropTypes.func.isRequired,
  updateRowAppId: PropTypes.func.isRequired,
  updateRowStatus: PropTypes.func.isRequired,
};


const Status = (props) => {
  const {
    message,
    status,
  } = props;

  return (
    <>
      { status === ROW_STATUS.SUCCESS
      && (
        <Tooltip title={message}>
          <CheckCircleIcon color="primary" />
        </Tooltip>
      )
      }
      { status === ROW_STATUS.INFO
      && (
        <Tooltip title={message}>
          <InfoIcon color="secondary" />
        </Tooltip>
      )
      }
      { status === ROW_STATUS.WARNING
      && (
        <Tooltip title={message}>
          <WarningIcon color="secondary" />
        </Tooltip>
      )
      }
      { status === ROW_STATUS.ERROR
      && (
        <Tooltip title={message}>
          <ErrorIcon color="error" />
        </Tooltip>
      )
      }
      { (status === ROW_STATUS.LOADING || status === ROW_STATUS.UPLOADING)
      && (
        <CircularProgress color="secondary" size={20} />
      )
      }
    </>
  );
};

Status.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};


const CsvTableRow = (props) => {
  const {
    appId, // eslint-disable-line
    data,
    date,
    isSelected,
    message,
    name,
    receiptId,
    receiptSubId,
    soilDiagItems,
    soilDiagRuleCode,
    soilDiagRules,
    status,
    toggleItem,
    updateRowAppId,
    updateRowStatus,
    uuid,
  } = props;

  const [application, setApplication] = useState({ // eslint-disable-line
    receipt_number: null,
    receipt_sub_number: null,
    company_name: null,
    requested_at: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      updateRowStatus(uuid, ROW_STATUS.LOADING, '');

      if (Number.isNaN(receiptId) || Number.isNaN(receiptSubId)) {
        updateRowStatus(uuid, ROW_STATUS.WARNING, '受付番号、枝番が正しくありません。');
        return;
      }

      if (date === 'Invalid date') {
        updateRowStatus(uuid, ROW_STATUS.WARNING, '受付日が正しくありません。');
        return;
      }

      const ruleCodes = soilDiagRules.map(rule => rule.provider_code);
      if (!ruleCodes.includes(soilDiagRuleCode)) {
        updateRowStatus(uuid, ROW_STATUS.WARNING, '診断基準が見つかりませんでした。');
        return;
      }

      if (data.some(d => Number.isNaN(d.computed))) {
        updateRowStatus(uuid, ROW_STATUS.WARNING, '診断値に読み込めないものがあります。');
        return;
      }

      let id = null;
      try {
        const applicationData = await getApplicationByNumber(receiptId, receiptSubId);
        id = applicationData.id; // eslint-disable-line
        const hasSoilDiagResult = !!applicationData.result_registered_at;
        updateRowAppId(uuid, id, hasSoilDiagResult);
        setApplication(applicationData);

        if (applicationData.status === 'canceled') {
          updateRowStatus(uuid, ROW_STATUS.WARNING, 'すでにキャンセル済みの申し込みです。');
          return;
        }

        if (applicationData.status === 'waiting_for_soil') {
          updateRowStatus(uuid, ROW_STATUS.WARNING, '土壌確認日が登録されていない申し込みです。');
          return;
        }

        if (applicationData.status === 'completed') {
          updateRowStatus(uuid, ROW_STATUS.WARNING, 'すでに配信済みの申し込みです。分析結果を再登録するには配信停止を先に行ってください。');
          return;
        }
      } catch (e) {
        updateRowStatus(uuid, ROW_STATUS.WARNING, '申し込みが取得できませんでした。');
        return;
      }

      try {
        const result = await getSoilDiagResult(id);
        if (result) {
          updateRowStatus(uuid, ROW_STATUS.INFO, 'すでに結果が登録されています。');
        }
      } catch (e) {
        // 結果が登録されていない状態、基本的にはこちらを想定している
        updateRowStatus(uuid, ROW_STATUS.READY, '');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onChangeCheckbox = useCallback(() => toggleItem(uuid), []);

  if (isLoading) {
    return (
      <TableRow>
        <TableCell>
          <Checkbox
            disabled
            value={uuid}
          />
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{receiptId}</TableCell>
        <TableCell>{receiptSubId}</TableCell>
        <TableCell />
        <TableCell>{application.requested_at}</TableCell>
        <TableCell>{application.company_name}</TableCell>
        <TableCell />
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          disabled={status !== ROW_STATUS.READY && status !== ROW_STATUS.INFO}
          checked={isSelected}
          onChange={onChangeCheckbox}
          value={uuid}
        />
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{receiptId}</TableCell>
      <TableCell>{receiptSubId}</TableCell>
      <TableCell>
        <Status message={message} status={status} />
      </TableCell>
      <TableCell>{application.requested_at}</TableCell>
      <TableCell>{application.company_name}</TableCell>
      <TableCell>
        <Preview
          date={date}
          data={data}
          receiptId={receiptId}
          receiptSubId={receiptSubId}
          soilDiagItems={soilDiagItems}
          soilDiagRuleCode={soilDiagRuleCode}
          soilDiagRules={soilDiagRules}
          title={name}
        />
      </TableCell>
    </TableRow>
  );
};

CsvTableRow.propTypes = {
  date: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(Data).isRequired,
  isSelected: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  receiptId: PropTypes.number.isRequired,
  receiptSubId: PropTypes.number.isRequired,
  soilDiagItems: PropTypes.arrayOf(SoilDiagItem).isRequired,
  soilDiagRuleCode: PropTypes.string.isRequired,
  soilDiagRules: PropTypes.arrayOf(Rule).isRequired,
  status: PropTypes.string.isRequired,
  toggleItem: PropTypes.func.isRequired,
  updateRowAppId: PropTypes.func.isRequired,
  updateRowStatus: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
};
