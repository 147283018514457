import React from 'react';
import {
  AppBar,
  Layout,
  UserMenu,
} from 'react-admin';

const divStyle = {
  marginLeft: '1em',
  marginRight: '1em',
};

const spanStyle = {
  marginLeft: '1em',
};

const MyUserMenu = (props) => {
  const companyName = window.localStorage.getItem('company_name');
  const accountName = window.localStorage.getItem('account_name');
  return (
    <>
      <div style={divStyle}>
        <span>{companyName}</span>
        <span style={spanStyle}>{accountName}</span>
      </div>
      <UserMenu {...props} />
    </>
  );
};

const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = props => (
  <Layout
    {...props}
    appBar={MyAppBar}
  />
);

export default MyLayout;
