export const VALUE_ROW_NAMES = [
  {
    system_key: 'ph_h2o',
    name: 'ｐＨ',
  },
  {
    system_key: 'ec',
    name: 'ＥＣ',
  },
  {
    system_key: 'yuukoutai_rinsan',
    name: '有効態リン酸',
  },
  {
    system_key: 'koukansei_sekkai',
    name: '交換性石灰',
  },
  {
    system_key: 'koukansei_kudo',
    name: '交換性苦土',
  },
  {
    system_key: 'koukansei_kari',
    name: '交換性加里',
  },
  {
    system_key: 'enki_houwado',
    name: '塩基飽和度',
  },
  {
    system_key: 'cec',
    name: '塩基置換容量',
  },
  {
    system_key: 'sekkai_kudohi',
    name: '石灰苦土比',
  },
  {
    system_key: 'kudo_karihi',
    name: '苦土加里比',
  },
  {
    system_key: 'syosantai_tisso',
    name: '硝酸態窒素',
  },
  {
    system_key: 'annmoniatai_tisso',
    name: 'アンモニア態窒素',
  },
  {
    system_key: 'hushoku',
    name: '腐植',
  },
  {
    system_key: 'rinsan_kyuushuu_keisuu',
    name: 'リン酸吸収係数',
  },
  {
    system_key: 'kakyuutai_tetsu',
    name: '可給態鉄',
  },
  {
    system_key: 'koukansei_mangan',
    name: '交換性マンガン',
  },
  {
    system_key: 'kakyuutai_aen',
    name: '可給態亜鉛',
  },
  {
    system_key: 'kakyuutai_dou',
    name: '可給態銅',
  },

  {
    system_key: 'kakyuutai_houso',
    name: 'ホウ素',
  },
];
