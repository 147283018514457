import { useEffect } from 'react';

export function useScrollToTop() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  return null;
}
