import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import Brown from '@material-ui/core/colors/brown';
import Grey from '@material-ui/core/colors/grey';

export const Dropzone = (props) => {
  const onDrop = useCallback(acceptedFiles => props.onFileDropped(acceptedFiles), []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const styles = {
    outer: {
      borderColor: isDragActive ? Brown['200'] : Grey['400'],
      borderStyle: 'dashed',
      borderWidth: 2,
      backgroundColor: isDragActive ? Brown['100'] : Grey['200'],
      textAlign: 'center',
      padding: 32,
    },
    caption: {
      color: Grey['700'],
      fontSize: '0.8em',
    },
  };

  return (
    <div {...getRootProps()} style={{ padding: 16 }}>
      <input {...getInputProps()} />
      <div style={styles.outer}>
        <p>ここにファイルをドロップ</p>
        <p style={styles.caption}>またはクリックしてファイルを選択</p>
      </div>
    </div>
  );
};

Dropzone.propTypes = {
  onFileDropped: PropTypes.func.isRequired,
};
