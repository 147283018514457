export const ROW_STATUS = {
  // 初期状態
  NONE: 'NONE',


  // データの読み込み中
  LOADING: 'LOADING',

  // アップロード中
  UPLOADING: 'UPLOADING',


  // 準備完了
  // アップロードの準備ができたときなど。
  READY: 'READY',

  // 情報
  // 処理は続行可能。
  // 対象のデータが存在していて上書きするときなど。
  INFO: 'INFO',

  // 警告
  // 処理が続行不可能。
  // CSVの値が誤っていた場合など、ユーザー自身が解決可能なもの。
  WARNING: 'WARNING',


  // 成功
  // 処理に成功した。
  SUCCESS: 'SUCCESS',

  // エラー
  // 処理に失敗した。
  // 基本的には想定外の理由で処理に失敗した時に使う。
  ERROR: 'ERROR',
};


// 処理済みの状態
const PROCESSED = [
  ROW_STATUS.WARNING,
  ROW_STATUS.SUCCESS,
  ROW_STATUS.ERROR,
];

// 現在の状態と次の状態を受け取って、状態とメッセージを設定して返す
// ルール外の更新は行わない
export function nextStatus(current, next) {
  // 処理済みから他の状態には遷移しない
  if (PROCESSED.includes(current.status)) {
    return current;
  }

  // 初期状態には戻らない
  if (next.status === ROW_STATUS.NONE) {
    return current;
  }

  return {
    ...current,
    message: next.message,
    status: next.status,
  };
}
