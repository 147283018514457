import React, { useCallback, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import createUUID from 'uuid/v4';

import Button from '@material-ui/core/Button/index';
import { withStyles } from '@material-ui/core/styles/index';

import { attachFileToApplication } from '../api/s3_upload';
import {
  nextStatus,
  ROW_STATUS,
} from '../utils/status';

import { Dropzone } from '../components/dropzone';
import { PdfTable } from '../components/pdf_table';

export const DROP_ITEMS = 'DROP_ITEMS';
export const RESET_ITEMS = 'RESET_ITEMS';
export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const UPDATE_ROW_APP_ID = 'UPDATE_ROW_APP_ID';
export const UPDATE_ROW_STATUS = 'UPDATE_ROW_STATUS';

const styles = theme => ({
  button: {
    marginRight: theme.spacing.unit * 2,
  },
  controls: {
    marginTop: theme.spacing.unit * 2,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case DROP_ITEMS:
      return action.payload.map(item => ({
        appId: null,
        isSelected: false,
        message: '',
        name: item.name,
        status: ROW_STATUS.NONE,
        uuid: item.uuid,
      }));

    case RESET_ITEMS:
      return [];

    case TOGGLE_ITEM:
      return state.map((item) => {
        if (action.meta === item.uuid) {
          return {
            ...item,
            isSelected: !item.isSelected,
          };
        }
        return item;
      });

    case UPDATE_ROW_APP_ID:
      return state.map((item) => {
        if (action.meta === item.uuid) {
          return {
            ...item,
            appId: action.payload,
          };
        }
        return item;
      });

    case UPDATE_ROW_STATUS:
      return state.map((item) => {
        if (action.meta === item.uuid) {
          return {
            ...item,
            ...nextStatus(item, action.payload),
          };
        }
        return item;
      });

    default:
      return state;
  }
};

const UploadPdf = (props) => {
  const { classes } = props;

  const [tableRows, dispatch] = useReducer(reducer, []);

  // ファイルの実体を管理するstate
  // Fileクラスをspread operatorでうっかりpure objectにしないように
  // reducerとは別に管理する
  const [files, setFiles] = useState([]); // eslint-disable-line

  const onFileDropped = useCallback((droppedFiles) => {
    const nextFiles = droppedFiles.map((file) => {
      // ファイルの実体を逆引きするためにUUIDをふる
      file.uuid = createUUID(); //eslint-disable-line
      return file;
    });
    setFiles(nextFiles);

    const action = {
      type: DROP_ITEMS,
      payload: nextFiles,
    };
    dispatch(action);
  }, []);

  const toggleItem = useCallback((targetUuid) => {
    const action = {
      type: TOGGLE_ITEM,
      meta: targetUuid,
    };
    dispatch(action);
  }, []);

  const updateRowAppId = useCallback((uuid, id) => {
    const action = {
      type: UPDATE_ROW_APP_ID,
      payload: id,
      meta: uuid,
    };
    dispatch(action);
  }, []);

  const updateRowStatus = (uuid, status, message) => {
    const action = {
      type: UPDATE_ROW_STATUS,
      payload: {
        message,
        status,
      },
      meta: uuid,
    };
    dispatch(action);
  };

  const onClickCancel = useCallback(() => {
    const action = {
      type: RESET_ITEMS,
    };
    dispatch(action);
  }, []);

  const isNoSelection = !tableRows.some(row => row.status === ROW_STATUS.READY && row.isSelected);
  const isFetchingItems = tableRows.some(row => row.status === ROW_STATUS.UPLOADING);
  const disableSubmit = isNoSelection || isFetchingItems;
  const disableCancel = isFetchingItems;

  const onClickSubmit = useCallback(() => {
    const filteredRows = tableRows.filter(row => row.isSelected && row.status === ROW_STATUS.READY);

    // Promise.settleの代わり、成否にかかわらず全部実行させる
    const promises = filteredRows.map(async row => new Promise((resolve) => {
      const target = files.find(file => file.uuid === row.uuid);
      if (!target) {
        updateRowStatus(target.uuid, ROW_STATUS.ERROR, 'アップロードに失敗しました。');
        resolve();
      }

      attachFileToApplication(row.appId, target)
        .then(() => {
          updateRowStatus(target.uuid, ROW_STATUS.SUCCESS, '');
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line
          updateRowStatus(target.uuid, ROW_STATUS.ERROR, 'アップロードに失敗しました。');
        })
        .finally(() => {
          resolve();
        });
    }));
    Promise.all(promises);
  }, [tableRows, files]);

  if (tableRows.length === 0) {
    return (
      <div>
        <h3>添付ファイル一括登録</h3>
        <Dropzone onFileDropped={onFileDropped} />
      </div>
    );
  }

  return (
    <div>
      <h3>添付ファイル一括登録</h3>
      <PdfTable
        disabled={isFetchingItems}
        rows={tableRows}
        toggleItem={toggleItem}
        updateRowAppId={updateRowAppId}
        updateRowStatus={updateRowStatus}
      />
      <div className={classes.controls}>
        <Button
          className={classes.button}
          variant="outlined"
          disabled={disableCancel}
          onClick={onClickCancel}
        >
          ファイルの選択に戻る
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={disableSubmit}
          onClick={onClickSubmit}
        >
          チェックした項目を登録
        </Button>
      </div>
    </div>
  );
};

UploadPdf.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPdf);
