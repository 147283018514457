import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CustomizableDatagrid from 'ra-customizable-datagrid';
import {
  CardActions,
  DateField,
  DateInput,
  downloadCSV,
  EmailField,
  ExportButton,
  Filter,
  List,
  NumberField,
  SelectField,
  SelectInput,
  setListSelectedIds,
  Show,
  refreshView,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { connect } from 'react-redux';

import {
  getLocale,
} from '../../utils/locale';

import {
  getApplicationsAll,
} from '../../api/requester';

import {
  toShiftJisCSV,
} from '../../csv/csv_exporter';

import {
  ApplicationStatuses,
} from './choices';

import { ToTopButton } from '../../components/buttons';
import { ResultForm } from '../../components/soil_diag_result';
import {
  AppCancelButton,
  BulkSoilReceivedUpdateButton,
  BulkDeliveryButton,
  DeliveryModal,
  RevertAppCancelButton,
  SoilReceivedUpdateModal,
} from '../../components/application';


const ApplicationFilter = props => (
  <Filter {...props}>
    <TextInput source="receipt_number" />
    <TextInput source="receipt_sub_number" />

    <SelectInput source="status" choices={ApplicationStatuses} />
    <DateInput source="created" />
    <DateInput source="soil_received_at" />
    <DateInput source="result_registered_at" />
    <DateInput source="result_delivered_at" />

    <TextInput source="crop_name" />
    <TextInput source="project_name" />
    <TextInput source="agri_field_name" />
    <TextInput source="agri_field_area" />

    <TextInput source="zip_code" />
    <TextInput source="prefecture" />
    <TextInput source="address" />
    <TextInput source="address2" />
    <TextInput source="email" />
    <TextInput source="phone" />
    <TextInput source="fax_number" />
    <TextInput source="mobile_phone" />
  </Filter>
);


const _ApplicationActionButtons = (props) => {
  const { selectedIds } = props;

  // 土壌到着日
  const [openSoilReceivedDate, setOpenSoilReceivedDate] = useState(false);

  const openModalForSoilReceivedDate = useCallback(() => {
    setOpenSoilReceivedDate(true);
  }, []);

  const closeModalForSoilReceivedDate = useCallback(() => {
    setOpenSoilReceivedDate(false);
  }, []);


  // 一括配信
  const [openDelivery, setOpenDelivery] = useState(false);

  const openModalForDelivery = useCallback(() => {
    setOpenDelivery(true);
  }, []);

  const closeModalForDelivery = useCallback(() => {
    setOpenDelivery(false);
  }, []);

  return (
    <>
      <SoilReceivedUpdateModal
        ids={selectedIds}
        closeFunc={closeModalForSoilReceivedDate}
        open={openSoilReceivedDate}
      />
      <BulkSoilReceivedUpdateButton onClick={openModalForSoilReceivedDate} />
      <DeliveryModal
        ids={selectedIds}
        closeFunc={closeModalForDelivery}
        open={openDelivery}
      />
      <BulkDeliveryButton onClick={openModalForDelivery} />
    </>
  );
};

_ApplicationActionButtons.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
};

_ApplicationActionButtons.defaultProps = {
  selectedIds: [],
};

const ApplicationActionButtons = connect(null, { refresh: refreshView })(_ApplicationActionButtons);

/* eslint-disable react/prop-types */
const ApplicationListActions = (props) => {
  const {
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
  } = props;

  const maxResults = 100; // TODO あとでちゃんとする

  return (
    <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <ExportButton
        maxResults={maxResults}
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </CardActions>
  );
};
/* eslint-enable react/prop-types */

const exporter = () => {
  getApplicationsAll().then((res) => {
    downloadCSV(toShiftJisCSV(res, 'applications'), 'applications');
  });
};

const _ApplicationList = (props) => {
  useEffect(() => {
    const { refreshSelection } = props;
    refreshSelection();
  }, []);

  // 初期のrenderなどでpropsにtranslateが渡されてない時はkeyをそのまま返す
  let translate = key => key;
  if (props.translate) { // eslint-disable-line
    translate = props.translate; // eslint-disable-line
  }

  const t = key => translate(`resources.applications.fields.${key}`);

  const defaultColumns = [
    'receipt_number',
    'receipt_sub_number',
    'status',
    'created',
    'soil_received_at',
    'result_registered_at',
    'result_delivered_at',
    'crop_name',
    'company_name',
    'prefecture',
  ];

  return (
    <List
      {...props}
      actions={<ApplicationListActions />}
      bulkActionButtons={<ApplicationActionButtons />}
      exporter={exporter}
      filters={<ApplicationFilter />}
    >
      <CustomizableDatagrid rowClick="show" defaultColumns={defaultColumns}>
        <NumberField source="receipt_number" label={t('receipt_number')} />
        <NumberField source="receipt_sub_number" label={t('receipt_sub_number')} />

        <SelectField source="status" choices={ApplicationStatuses} label={t('status')} />
        <DateField source="created" locales={getLocale()} label={t('created')} />
        <DateField source="soil_received_at" locales={getLocale()} label={t('soil_received_at')} />
        <DateField source="result_registered_at" locales={getLocale()} label={t('result_registered_at')} />
        <DateField source="result_delivered_at" locales={getLocale()} label={t('result_delivered_at')} />

        <TextField source="crop_name" label={t('crop_name')} />
        <TextField source="project_name" label={t('project_name')} />
        <TextField source="agri_field_name" label={t('agri_field_name')} />
        <NumberField source="agri_field_area" label={t('agri_field_area')} />

        <TextField source="company_name" label={t('company_name')} />
        <TextField source="company_name_kana" label={t('company_name_kana')} />
        <TextField source="producer_name" label={t('producer_name')} />
        <TextField source="producer_name_kana" label={t('producer_name_kana')} />

        <TextField source="zip_code" label={t('zip_code')} />
        <TextField source="prefecture" label={t('prefecture')} />
        <TextField source="address" label={t('address')} />
        <TextField source="address2" label={t('address2')} />
        <EmailField source="email" label={t('email')} />
        <TextField source="phone" label={t('phone')} />
        <TextField source="fax_number" label={t('fax_number')} />
        <TextField source="mobile_phone" label={t('mobile_phone')} />
      </CustomizableDatagrid>
    </List>
  );
};

_ApplicationList.propTypes = {
  refreshSelection: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  refreshSelection: () => setListSelectedIds('applications', []),
};
export const ApplicationList = connect(null, mapDispatchToProps)(_ApplicationList);


const ApplicationShowActions = ({ data }) => {
  let button;
  if (data && data.status !== 'completed') {
    if (data.canceled_at) {
      button = <RevertAppCancelButton id={data.id} />;
    } else {
      button = <AppCancelButton id={data.id} />;
    }
  }

  return (
    <CardActions>
      {button}
    </CardActions>
  );
};

ApplicationShowActions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};


export const ApplicationShow = (props) => {
  let translate = key => key;
  if (props.translate) { // eslint-disable-line
    translate = props.translate; // eslint-disable-line
  }
  const t = key => translate(`ui.applications.${key}`);

  const { id } = props;
  return (
    <>
      <Show
        actions={<ApplicationShowActions />}
        {...props}
      >
        <TabbedShowLayout>
          <Tab label={t('application')}>
            <NumberField source="receipt_number" />
            <NumberField source="receipt_sub_number" />

            <SelectField source="status" choices={ApplicationStatuses} />
            <DateField source="created" locales={getLocale()} />
            <DateField source="soil_received_at" locales={getLocale()} />
            <DateField source="result_registered_at" locales={getLocale()} />
            <DateField source="result_delivered_at" locales={getLocale()} />

            <TextField source="crop_name" />
            <TextField source="project_name" />
            <TextField source="agri_field_name" />
            <NumberField source="agri_field_area" />
          </Tab>
          <Tab label={t('user')}>
            <TextField source="company_name" />
            <TextField source="company_name_kana" />
            <TextField source="producer_name" />
            <TextField source="producer_name_kana" />

            <TextField source="zip_code" />
            <TextField source="prefecture" />
            <TextField source="address" />
            <TextField source="address2" />
            <EmailField source="email" />
            <TextField source="phone" />
            <TextField source="fax_number" />
            <TextField source="mobile_phone" />
          </Tab>
          <Tab label={t('result')}>
            <ResultForm applicationId={id} />
          </Tab>
        </TabbedShowLayout>
      </Show>
      <ToTopButton />
    </>
  );
};

ApplicationShow.propTypes = {
  id: PropTypes.string.isRequired,
};
