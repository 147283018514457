import japaneseMessages from 'ra-language-japanese';
import { override } from './customized_ja';

const domainMessages = {
  resources: {
    users: {
      name: 'ユーザー',
      fields: {
        id: 'ID',
        company_name: '組織名',
        company_name_kana: '組織名（カナ）',
        producer_name: '代表者氏名',
        producer_name_kana: '代表者氏名（カナ）',
        zip_code: '郵便番号',
        prefecture: '事務所所在地（都道府県）',
        address: '事務所所在地（住所）',
        address2: '事務所所在地（住所2）',
        email: 'E-Mail',
        phone: '電話番号（固定）',
        mobile_phone: '電話番号（携帯）',
        fax_number: 'FAX番号',
        contractor: '代理店名',
        created: '登録日',
        status: '状態',
      },
    },
    applications: {
      name: '申し込み',
      fields: {
        id: 'ID',
        receipt_number: '受付番号',
        receipt_sub_number: '枝番',
        crop_name: '作目名',
        project_name: '作付名',
        agri_field_area: '作付面積',
        agri_field_name: '圃場名',
        requested_at: '申し込み受付日',
        result_delivered_at: '結果配信日',
        result_registered_at: '結果登録日',
        soil_received_at: '土壌到着日',
        payment_requested_at: '入金依頼日',
        payment_received_at: '入金確認日',
        canceled_at: 'キャンセル日',
        provider_code: 'コード',
        // usersのコピー
        company_name: '組織名',
        company_name_kana: '組織名（カナ）',
        producer_name: '代表者氏名',
        producer_name_kana: '代表者氏名（カナ）',
        zip_code: '郵便番号',
        prefecture: '事務所所在地（都道府県）',
        address: '事務所所在地（住所）',
        address2: '事務所所在地（住所2）',
        email: 'E-Mail',
        phone: '電話番号（固定）',
        mobile_phone: '電話番号（携帯）',
        fax_number: 'FAX番号',
        contractor: '代理店名',
        created: '受付日',
        status: '状態',

        system_form: {
          course: 'コース',
          zip_code: '郵便番号',
          address: '住所',
          name: '名前',
          fertilization_plan_required: '施肥設計',
          fertilization_basis_n: '施肥設計基準 - N',
          fertilization_basis_p: '施肥設計基準 - P',
          fertilization_basis_k: '施肥設計基準 - K',
          comment_required: 'コメント',
          other: '備考',
          land_type: '地目',

          soil_send_date: 'サンプル発送予定日',
        },
      },
    },
    message_templates: {
      name: '定型文',
      fields: {
        id: 'ID',
        system_name: '送信タイミング',
        title: 'メッセージタイトル',
        body: '本文',
      },
    },
    soil_diagnosis_rules: {
      name: '土壌診断基準',
      fields: {
        id: 'ID',
        name: '診断基準名',
        provider_code: '診断基準コード',
        memo: 'メモ',
        updated_at: '更新日',
        values: '診断基準値',
        item_id: '診断項目',
        high: '上限値',
        low: '下限値',
        created_at: '作成日',
        type: '区分',
        ec: 'EC',
        annmoniatai_tisso: 'アンモニア態窒素',
        syosantai_tisso: '硝酸態窒素',
        yuukoutai_rinsan: '有効態リン酸',
        koukansei_kari: '交換性加里',
        koukansei_sekkai: '交換性石灰',
        koukansei_kudo: '交換性苦土',
        kakyuutai_aen: '可給態亜鉛',
        sekkai_kudohi: '石灰苦土比',
        cec: 'CEC',
        enki_houwado: '塩基飽和度',
        hushoku: '腐植',
        ph_h2o: 'pH (H2O)',
        rinsan_kyuushuu_keisuu: 'リン酸吸収係数',
        kakyuutai_tetsu: '可給態鉄',
        koukansei_mangan: '交換性マンガン',
        kakyuutai_dou: '可給態銅',
        kakyuutai_houso: '可給態ホウ素',
        kudo_karihi: '苦土加里比',
      },
    },
    user_operation_logs: {
      name: '操作ログ',
      fields: {
        id: 'ID',
        account_id: 'アカウントID',
        account_name: 'アカウント名',
        created: '操作日時',
        command: '操作内容',
        parameters: {
          id: '対象ID',
          receipt_number: '受付番号',
          receipt_sub_number: '枝番',
        },
      },
    },
  },

  ui: {
    users: {
      details: '基本情報',
      contacts: '連絡先',
    },
    applications: {
      application: '申し込み情報',
      user: 'ユーザー情報',
      form: 'フォーム情報',
      result: '土壌診断結果確認・配信',
    },
    message_templates: {
      preview: 'プレビュー',
    },
    upload_csv: {
      name: '分析結果一括登録',
    },
    upload_pdf: {
      name: '添付ファイル一括登録',
    },
  },

  Unauthorized: '認証に失敗しました',
  'Failed to fetch': '通信に失敗しました',
  'Gateway Timeout': 'タイムアウトしました',
  'low > high': '下限値が上限値よりも大きいです',
  'rule_id is referenced elsewhere and cannot be deleted': 'すでに診断結果に使われているため削除できませんでした',
};

export default {
  ...override(japaneseMessages),
  ...domainMessages,
};
