import React from 'react';
import PropTypes from 'prop-types';

import compose from 'recompose/compose';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grey from '@material-ui/core/colors/grey';
import { translate } from 'react-admin';
import {
  Link,
  withRouter,
} from 'react-router-dom';

import CardIcon from './card_icon';

const styles = {
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  link: {
    textDecoration: 'none',
    color: Grey['700'],
  },
};

const Summary = ({
  bgColor,
  classes,
  icon,
  label,
  to,
  value,
}) => (
  <div className={classes.main}>
    <Link to={to} className={classes.link}>
      <CardIcon Icon={icon} bgColor={bgColor} />
      <Card className={classes.card}>
        <Typography color="textSecondary">
          {label}
        </Typography>
        <Typography variant="headline" component="h2">
          {value}
        </Typography>
      </Card>
    </Link>
  </div>
);

Summary.propTypes = {
  bgColor: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
    main: PropTypes.string.isRequired,
  }).isRequired,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const enhance = compose(
  withRouter,
  withStyles(styles),
  translate,
);

export default enhance(Summary);
