import moment from 'moment';
import { VALUE_ROW_NAMES } from './value_rows';
import {
  getValueRowNames,
  getSystemKeyByName,
} from '../../utils/row_name';

// consts
export const COLUMN_TYPES = {
  ID: 'ID',
  DATE: 'DATE',
  TEXT: 'TEXT',
  VALUE: 'VALUE',
};

const TYPE_COLUMN = '区分';
const ROW_TYPES = {
  DATA: '分析値',
  LOWER_LIMIT: '診断基準値(低)',
  UPPER_LIMIT: '診断基準値(高)',
};

const RECEIPT_ID_COLUMN = '受付番号';
const RECEIPT_SUB_ID_COLUMN = '枝番号';
const SAMPLING_DATE_COLUMN = '採土日';
const TITLE_COLUMN = 'タイトル';
const COMMENT_COLUMN = 'コメント';


const ID_COLUMNS = [
  RECEIPT_ID_COLUMN,
  RECEIPT_SUB_ID_COLUMN,
];

const DATE_COLUMNS = [
  SAMPLING_DATE_COLUMN,
];

const TEXT_COLUMNS = [
  TITLE_COLUMN,
  COMMENT_COLUMN,
];

const VALUE_COLUMNS = getValueRowNames(VALUE_ROW_NAMES);

// util
export function getColumnType(index, header) {
  const label = header[index];

  if (ID_COLUMNS.includes(label)) {
    return COLUMN_TYPES.ID;
  }

  if (DATE_COLUMNS.includes(label)) {
    return COLUMN_TYPES.DATE;
  }

  if (TEXT_COLUMNS.includes(label)) {
    return COLUMN_TYPES.TEXT;
  }

  if (VALUE_COLUMNS.includes(label)) {
    return COLUMN_TYPES.VALUE;
  }

  return null; // parse対象外
}

// validator
function validateId(value) {
  const regexp = /^([1-9]\d*)$/;
  if (!regexp.test(value)) {
    return false;
  }
  return !Number.isNaN(parseInt(value, 10));
}

function validateDate(value) {
  return !Number.isNaN((new Date(value).getTime()));
}

function validateValue(value) {
  if (value.trim() === '') {
    return true;
  }

  const csvStrippedValue = value.replace(',', '');
  const regexp = /^((([1-9]\d*\.*\d*)|(0\.\d+)))$/;
  if (!regexp.test(csvStrippedValue)) {
    return false;
  }
  return !Number.isNaN(parseFloat(csvStrippedValue));
}

function validateColumn(value, index, header) {
  const columnType = getColumnType(index, header);

  switch (columnType) {
    case COLUMN_TYPES.ID:
      return validateId(value);

    case COLUMN_TYPES.DATE:
      return validateDate(value);

    case COLUMN_TYPES.VALUE:
      return validateValue(value);

    case COLUMN_TYPES.TEXT:
      return true; // 文字列は無条件でtrue

    default:
      return true; // 対象外のカラム
  }
}

export function validateRow(row, header) {
  return row.every((col, i) => validateColumn(col, i, header));
}

export function validateRowSet(rows, header) {
  const typeIndex = header.indexOf(TYPE_COLUMN);
  const dataRows = rows.filter(row => row[typeIndex] === ROW_TYPES.DATA);
  const lowerLimitRows = rows.filter(row => row[typeIndex] === ROW_TYPES.LOWER_LIMIT);
  const upperLimitRows = rows.filter(row => row[typeIndex] === ROW_TYPES.UPPER_LIMIT);

  let isValidData = dataRows.length === 1;
  let isValidLowerLimit = lowerLimitRows.length === 1;
  let isValidUpperLimit = upperLimitRows.length === 1;

  isValidData = isValidData && dataRows[0].every((col, i) => validateColumn(col, i, header));
  isValidLowerLimit = isValidLowerLimit
    && lowerLimitRows[0].every((col, i) => validateColumn(col, i, header));
  isValidUpperLimit = isValidUpperLimit
    && upperLimitRows[0].every((col, i) => validateColumn(col, i, header));

  return isValidData && isValidLowerLimit && isValidUpperLimit;
}

// parser
function parseId(value) {
  return parseInt(value, 10);
}

function parseDate(value) {
  return moment(value).format('YYYY-MM-DD');
}

function parseValue(value) {
  if (value === '') {
    return null;
  }

  const csvStrippedValue = value.replace(',', '');
  return parseFloat(csvStrippedValue);
}

function getValue(row, label, header) {
  const index = header.indexOf(label);
  if (index === -1) {
    return null;
  }
  return row[index];
}

const parseData = (row, header, soilDiagItems) => row.reduce((memo, value, i) => {
  if (!VALUE_COLUMNS.includes(header[i])) {
    return memo;
  }

  const parsedValue = parseValue(value);
  const item = soilDiagItems.find((sdi) => {
    const key = getSystemKeyByName(header[i], VALUE_ROW_NAMES);
    return sdi.system_key === key;
  });
  if (parsedValue !== null && item) {
    memo.push({
      item_id: item.id,
      values: [parsedValue],
      computed: parsedValue,
    });
  }
  return memo;
}, []);

export function parseRowSet(rows, header, soilDiagItems) {
  const typeIndex = header.indexOf(TYPE_COLUMN);

  let receiptId;
  let receiptSubId;
  let date;
  let data;
  let lowerLimit;
  let upperLimit;

  // 不正なデータの場合に行が欠損していることを考慮して、
  // 上限値 => 下限値 => 測定値の順で辿ってデータを埋める
  const upperLimitRow = rows.find(row => row[typeIndex] === ROW_TYPES.UPPER_LIMIT);
  if (upperLimitRow) {
    receiptId = parseId(getValue(upperLimitRow, RECEIPT_ID_COLUMN, header));
    receiptSubId = parseId(getValue(upperLimitRow, RECEIPT_SUB_ID_COLUMN, header));
    date = parseDate(getValue(upperLimitRow, SAMPLING_DATE_COLUMN, header));
    upperLimit = parseData(upperLimitRow, header, soilDiagItems);
  }

  const lowerLimitRow = rows.find(row => row[typeIndex] === ROW_TYPES.LOWER_LIMIT);
  if (lowerLimitRow) {
    receiptId = parseId(getValue(lowerLimitRow, RECEIPT_ID_COLUMN, header));
    receiptSubId = parseId(getValue(lowerLimitRow, RECEIPT_SUB_ID_COLUMN, header));
    date = parseDate(getValue(lowerLimitRow, SAMPLING_DATE_COLUMN, header));
    lowerLimit = parseData(lowerLimitRow, header, soilDiagItems);
  }

  const dataRow = rows.find(row => row[typeIndex] === ROW_TYPES.DATA);
  if (dataRow) {
    receiptId = parseId(getValue(dataRow, RECEIPT_ID_COLUMN, header));
    receiptSubId = parseId(getValue(dataRow, RECEIPT_SUB_ID_COLUMN, header));
    date = parseDate(getValue(dataRow, SAMPLING_DATE_COLUMN, header));
    data = parseData(dataRow, header, soilDiagItems);
  }

  return {
    receiptId,
    receiptSubId,
    date,
    data,
    lowerLimit,
    upperLimit,
  };
}

export function parseBody(rows, header, soilDiagItems) {
  const idIndex = header.indexOf(RECEIPT_ID_COLUMN);
  const subIdIndex = header.indexOf(RECEIPT_SUB_ID_COLUMN);
  const pairs = rows.reduce((memo, row) => {
    const target = memo.find(m => m[0] === row[idIndex] && m[1] === row[subIdIndex]);
    if (!target) {
      memo.push([row[idIndex], row[subIdIndex]]);
    }
    return memo;
  }, []);
  return pairs.map((pair) => {
    const rowSet = rows.filter(row => row[idIndex] === pair[0] && row[subIdIndex] === pair[1]);
    return parseRowSet(rowSet, header, soilDiagItems);
  });
}

// パース済オブジェクトからデータを取得する
// 値がnullの測定値は対象外として弾く
export function getValues(parsed) {
  const isValid = parsed.data.every(d => !Number.isNaN(d.computed));
  if (!isValid) {
    throw Error('Invalid value in CSV row');
  }
  return parsed.data
    .filter(d => d.computed !== null)
    .map(d => ({
      item_id: d.item_id,
      values: d.values,
      computed: d.computed,
    }));
}
